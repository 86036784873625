*,
*::after,
*::before {
  box-sizing: border-box;
}

a:active {
  font-weight: bold;
}

a,
a:hover,
a:link,
a:visited {
  text-decoration: none;
}

h1,
h2 {
  font-size: 20px;
}

h3 {
  font-size: 17px;
}

h4 {
  font-size: 15px;
}

.mesh-row {
  padding: initial;
  max-width: 1220px;
  margin: 0 auto;
  position: relative;
}

.content {
  overflow: hidden;
}

.one-column__content {
  max-width: 1220px;
  margin: 56px auto 0;
  padding: 0 20px;
}

.banner {
  height: 180px;
  width: 100%;
  background: #000;

  img {
    width: 100%;
    height: 180px;
    opacity: 0.5;
  }
}

.page-header {
  position: absolute;
  top: -155px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;

  h1 {
    font-weight: 300;
    margin: 0;
    color: #fff;
    font-size: 32px;
  }

  h4 {
    font-size: 15px;
    font-weight: 300;
    max-width: 692px;
    line-height: 24px;
    margin: 0 auto;
    color: #fff;
  }
}

section {
  clear: both;
  padding: 0;
}

.page-form {
  padding: 20px 30px 0;
}

// Custom css

p {
  margin: 0;
  line-height: 26px;
  font-size: 15px;
}

.content-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.post-content-list {
  list-style: none;
  margin: 15px 0;
  padding: 0;
}

.list-item {
  overflow: hidden;
  margin: 15px 0;
}

.list-item-title {
  font-size: 28px;
  line-height: 26px;
  font-weight: 300;
  margin: 0;
}

.list-item-text {
  margin: 10px 0 5px;
  line-height: 20px;
}

.list-item-link {
  margin-right: 25px;
}

.list-item + .list-item {
  margin-top: 65px;
}

.list-item-icon {
  float: left;
  margin-top: 20px;
  text-align: center;
}

.list-item-container {
  margin-left: 85px;
  max-width: 600px;
}

.sdk-languajes a {
  display: inline-block;
  margin: 5px 2px;
}

.guide-selection a {
  padding: 10px 8px;
  margin: 0;
  border-radius: 70px;
  display: inline-block;
}

.guide-selection-label {
  display: block;
  width: 82px;
  margin-top: -15px;
  color: #2d3277;
  text-align: center;
  font-size: 14px;
}

/* --------------------------------------------------------------
## Terminal
-------------------------------------------------------------- */

pre.terminal,
pre.code {
  padding: 18px 20px 15px;
  font-size: 14px;
  line-height: 19px;
  border: 1px solid #ddd;
  margin: 0 0 10px;
  max-height: 450px;
  background-color: #eee;
}

pre.terminal + pre.terminal,
pre.code + pre.code {
  margin-top: 15px;
}

.pre-label {
  display: block;
  margin: 25px 0 8px;
}

@media (width <= 767px) {
  pre.terminal,
  pre.code {
    max-height: 350px;
  }
}

pre {
  max-width: 100%;
  overflow: auto;
}

/* --------------------------------------------------------------
## Custom Table
-------------------------------------------------------------- */

.custom-table {
  font-size: 14px;
  border-collapse: collapse;
}

.custom-table th {
  text-align: left;
  padding: 10px;
  color: #fff;
  font-weight: 400;
  background: #a3a3c2;
  font-size: 14px;
}

.custom-table tr {
  border-bottom: 1px solid #a3a3c2;
}

.custom-table td {
  padding: 10px;
  word-break: break-word;
}

.custom-table.break-normal td {
  word-break: normal;
}

.custom-table td:first-child {
  font-weight: 700;
}

.custom-table td:first-child p,
.custom-table td:first-child span {
  font-weight: 400;
}

.custom-table.no-bolded td:first-child {
  font-weight: 400;
}

.custom-table.align-center th {
  text-align: center;
}

.custom-table.two-columns td {
  width: 50%;
}

.custom-table tr.row-even {
  background: #f1f1f1;
}

@media (width <= 767px) {
  .custom-table-container {
    width: 100%;
    overflow-x: scroll;
  }

  .custom-table.several-columns {
    width: 850px;
  }
}

/* --------------------------------------------------------------
## Modals
-------------------------------------------------------------- */
.modalmask {
  position: fixed;
  font-family: Arial, sans-serif;
  inset: 0;
  background: rgb(0 0 0 / 80%);
  z-index: 99999;
  opacity: 0;
  transition: opacity 400ms ease-in;
  pointer-events: none;
  overflow-x: scroll;
}

.modalmask:target {
  opacity: 1;
  pointer-events: auto;
}

.modalbox {
  max-width: 800px;
  position: relative;
  padding: 40px 30px;
  background: #fff;
  transition: all 500ms ease-in;
}

.modalbox pre.terminal,
.modalbox pre.code {
  max-height: 220px;
  background-color: #eee;
}

.movedown {
  margin: 0 auto;
}

.modalmask:target .movedown {
  margin: 6% auto 0;
}

.modalbox .close,
.modalbox .close:link,
.modalbox .close:visited,
.modalbox .close:hover,
.modalbox .close:active,
.modalbox .go-back,
.modalbox .go-back:link,
.modalbox .go-back:visited,
.modalbox .go-back:hover,
.modalbox .go-back:active {
  color: #222;
}

.modalbox .close {
  line-height: 25px;
  position: absolute;
  right: 1px;
  text-align: center;
  top: 1px;
  width: 24px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 3px;
}

.modalbox .close:hover {
  background: #eee;
  color: #222;
}

.modalbox .go-back {
  display: none;
}

.modalbox h2 {
  margin-top: 0;
}

@media (width <= 767px) {
  .modalbox {
    width: 100%;
  }

  .modalmask:target .movedown {
    margin: 0;
    padding: 45px 15px 30px;
    min-height: 100%;
  }

  .api-doc-table .col-3 .modalbox .close {
    display: none;
  }

  .modalbox .go-back {
    display: block;
    position: absolute;
    top: 10px;
    color: #4a4a4a;
    font-size: 15px;
    line-height: 18px;
    padding: 10px 0;
  }

  .modalbox .go-back::before {
    color: #4a4a4a;
    content: '\e806';
    font-family: navigation;
    float: left;
    margin-right: 8px;
    margin-top: -2px;
    transform: rotate(180deg);
  }

  .modalbox pre.terminal,
  .modalbox pre.code {
    max-height: none;
  }
}

/**
 * Styling top level items
 */

.nav a,
.nav label {
  display: block;
  padding: 10px 0 10px 8px;
  color: #666;
}

// para proxima implementacion en lista desplegable seleccionada
// li.list-selected {
//   border-left: 3px solid #3483fa;
// }

.fa-angle-right {
  color: #3483fa;
  font-weight: 600;
}

.nav a:focus,
.nav a:hover,
.nav label:focus,
.nav label:hover,
.nav label:active {
  color: #888;
}

.nav label {
  cursor: pointer;
}

.group-list a,
.group-list label {
  padding-left: 10px;
}

.group-list {
  margin: 0;
  padding: 10px 0 0 5px;
  box-shadow: inset 0 -1px #eee;
}

.sub-group-list {
  margin: 0;
  padding: 10px 0 0 13px;
}

/**
 * Styling events
 */

.event-heading {
  padding: 10px;
  font-size: 15px;
  margin: 0 16px 10px 0;
}

.event-day {
  background-color: #e10b56;
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  width: 64px;
  height: 64px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  line-height: normal;
  border-radius: 5px 0 0 5px;
  padding: 5px 5px 5px 8px;
}

.event-month-place {
  background-color: #4a4a4a;
  color: #fff;
  width: 81%;
  height: 64px;
  padding: 5px 0 0 10px;
  vertical-align: middle;
  border-radius: 0 5px 5px 0;
  display: inline-block;
}

.event-date {
  display: block;
}

.event-place {
  display: inline-block;
}

.event-time {
  display: inline-block;
}

.nav-icon-location {
  font-size: 20px;
  vertical-align: text-top;
  margin: 0 5px;
}

.nav-icon-location::before {
  content: '\EA17';
}

a.post-selected {
  font-weight: bold;
  color: #111;
  font-size: 15px;
}

.nav-icon-clock {
  font-size: 20px;
  vertical-align: text-top;
  margin: 0 3px 0 15px;
}

.nav-icon-clock::before {
  content: '\EA18';
}

.button-inscreva {
  border: solid 1px #2d3277;
  border-radius: 6px;
  color: #2d3277;
  display: block;
  font-family: Arial-MT, Arial;
  font-size: 15px;
  margin: auto;
  padding: 10px 15px;
  text-align: center;
  width: 137px;
}

/**
 * Hide nested lists
 */

.group-list,
.sub-group-list,
.sub-sub-group-list,
.sub-sub-sub-group-list,
.sub-sub-sub-sub-group-list {
  height: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.nav__list input[type='checkbox']:checked + label + ul {
  max-height: 1000px;
}

.nav__list input[type='checkbox']:checked + label {
  font-weight: 500;
}

.nav label > span {
  float: right;
  transition: -webkit-transform 0.65s ease;
  transition: transform 0.65s ease;
}

.nav__list input[type='checkbox']:checked + label > span {
  transform: rotate(-90deg);
}

.nav__list {
  list-style-type: none;
  margin-bottom: 60px;
  padding-right: 20px;
  font-size: 14px;

  ul {
    list-style-type: none;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
  }
}

/* --------------------------------------------------------------
## Get Token
-------------------------------------------------------------- */

/**
 * TODO: change or delete this inherited style
 */
button,
input {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button {
  text-transform: none;
}

.button-link-outline {
  color: #fff;
  padding: 10px 20px;
  border: 2px solid #fff;
  border-radius: 6px;
  font-size: 18px;
}

.button-link-outline,
.button-link-outline:link,
.button-link-outline:visited,
.button-link-outline:hover,
.button-link-outline:active {
  color: #fff;
}

.button-purple,
.button-purple:link,
.button-purple:visited,
.button-purple:hover,
.button-purple:active {
  color: #62628b;
  border-color: #62628b;
}
@media (width <= 767px) {
  .button-link-outline {
    font-size: 1em;
  }
}

.custom-form {
  margin-top: 20px;
}

.custom-form input[type='text'] {
  height: 42px;
  margin: 20px 10px 0 0;
  padding: 0 15px;
  outline: none;
}

.custom-form .button-link-outline {
  border: 1px solid #62628b;
  display: inline-block;
  line-height: 25px;
  vertical-align: middle;
  padding: 8px 20px;
  cursor: pointer;
  background: #fff;
}

.custom-form-error {
  display: none;
  font-size: 15px;
  color: red;
}

.custom-form-error em {
  margin-right: 5px;
}
@media (width <= 767px) {
  .custom-form input[type='text'] {
    width: 100%;
    margin-bottom: 10px;
  }
}

pre.code {
  background: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/s3/372745203693-pre_bg.gif')
    repeat;
  padding: 18px 20px 15px;
  font-size: 13px;
  line-height: 19px;
  border: 1px solid #ddd;
  margin: 0 0 10px;
  max-height: 450px;
}
@media (width <= 767px) {
  pre.code {
    max-height: 350px;
  }
}

/* One Open Platform Landing page
--------------------------------------------------------------- */

.mesh-col-l-12.mesh-row__content {
  padding: 0;
}

.section-content {
  margin: 0 auto;
  max-width: 970px;
}

.title-container {
  .section-title {
    font-size: 30px;
    font-weight: 100;
    text-align: center;
  }
}

.section-text-info-container {
  .section-text-info {
    color: #333;
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
  }
}

.visible {
  display: block;
}

.modal-flag {
  background-repeat: no-repeat;
  display: inline-block;
  height: 12px;
  width: 18px;
  margin: 0 0 0 15px;
}

/* Banner
    --------------------------------------------------------------- */

.section-content.banner-content {
  background-color: #ebebeb;
  max-width: 100%;
  padding-top: 50px;
  position: relative;
  width: 100%;
  padding: 0 15px 40px;
}

.section-content.banner-content::after {
  position: absolute;
  top: 98.1%;
  content: '';
  width: 0;
  height: 0;
  left: 70%;
  margin-left: -115px;
  border-top: solid 40px #ebebeb;
  border-left: solid 40px transparent;
  border-right: solid 40px transparent;
}

.banner-content__main-container {
  .banner-content_img-container,
  .banner-content__info-container {
    display: block;
    color: #333;
    font-weight: 100;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
  }

  .banner-content_img {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/367995825201-Meli-ecosystem.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 285px;
    margin: 0 auto;
    width: 320px;
    max-height: 100%;
    display: block;
  }
}

.section-content.platforms {
  content: '';
  margin-bottom: 20px;
  padding: 15px 0;
  position: relative;
  height: auto;
  width: 90%;
  max-width: 100%;
}

.platforms__main-container {
  margin: 0;
  padding: 0;
}

.section-content.platforms::after {
  position: absolute;
  top: 100%;
  left: 74%;
  margin-left: -123px;
  content: '';
  width: 0;
  height: 0;
  border-top: solid 40px #fff;
  border-left: solid 40px transparent;
  border-right: solid 40px transparent;
}

.platform__info-content.meli-plat {
  visibility: hidden;
}

.platform__info-content.meli-plat.visible {
  background-color: #ffe600;
  top: -565px;
  visibility: inherit;
}

.meli-plat__subrpoducts-container-wrapper {
  padding: 0 20px;
  margin: 30px 0;
}

.meli-plat__subrpoducts-container {
  display: table;
  width: 100%;

  span {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    display: table-cell;
    height: 100px;
    width: 100px;
  }

  .meli_clasi {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368513203546-ML-clasi_over.png');
  }

  .meli_me {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368513203546-ME_over.png');
  }

  .meli_markplc {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368513203546-ML-mkt_over.png');
  }
}

.platform__info-content-close-container {
  display: block;
  width: 100%;
}

a.platform__docs {
  border: solid 1px #000;
  color: #333;
  font-size: 16px;
  font-weight: normal;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  width: 70%;
  display: block;
}

.platform__info-content {
  position: absolute;
  opacity: 1;
  inset: 550px 0 0;
  transition: all 0.6s ease-in-out;
  background-color: inherit;
  visibility: hidden;

  .platform__info-content-close {
    color: #fff;
    text-align: right;
    float: right;
  }

  .mlp,
  p,
  .platform__info-p {
    color: #333;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.5;
    padding: 0 15px;
    text-align: center;
  }
}

a.platform__info-content-close.mlp-close {
  color: #333;
}

.platform__info-content.visible {
  top: 0;
  visibility: inherit;
}

.ms,
.mp,
.bo {
  .platform__info-content.visible {
    top: 0;

    p,
    a.platform__info-content-close {
      color: #fff;
    }
  }
}

.platform__transition-mob,
.platform__transition-mob-meli {
  display: block;
  float: right;
  margin-top: 15px;
}

.platform__links-container {
  display: block;
  margin: auto;
  width: 100%;

  .platform__links-doc {
    background-color: transparent;
    border: 1px solid #333;
    border-radius: 0;
    color: #333;
    display: block;
    font-size: 18px;
    padding: 10px 15px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 15px;
    font-weight: normal;
    line-height: 22px;
  }
}

.meli-plat {
  background-color: #fff485;
  float: none;
  height: 580px;
  margin-bottom: 20px;
  position: relative;
  padding: 1px 0 0;

  .platform__transition-mob-container {
    height: 30px;
  }

  .platform__logo-container {
    display: block;
    height: 475px;
    padding-top: 150px;

    .platform__logo {
      display: block;
      margin: 0 auto;
      width: 150px;
    }
  }

  .platform__info-content {
    .platform__info-p,
    p {
      padding: 0 15px;
      height: 270px;
    }
  }

  a.platform__info-content-close {
    color: #333;
    margin: 15px 15px 15px 0;
  }

  .platform__transition-mob {
    color: #333;
    margin-right: 15px;
  }

  .platform__logo-container {
    .platform__logo {
      background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368513203546-ML_logo.png');
      background-repeat: no-repeat;
      width: 150px;
      height: 135px;
      display: block;
    }
  }
}

.platform {
  height: 400px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  padding: 0 15px;

  .platform__transition-mob-container {
    margin-bottom: 110px;
  }

  .platform__logo-container {
    margin-bottom: 30px;

    .platform__logo {
      background-repeat: no-repeat;
      width: 100%;
      height: 175px;
      display: inline-block;
      background-position: center;
    }
  }

  .platform__info-content-close-container {
    height: 25px;
    margin-bottom: 0;

    a.platform__info-content-close {
      margin: 15px 15px 15px 0;
    }
  }
}

.platform-mlp {
  height: 400px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  padding: 0 15px;

  .platform__transition-mob-container {
    margin-bottom: 110px;
  }

  .platform__logo-container {
    margin-bottom: 30px;

    .platform__logo {
      background-repeat: no-repeat;
      width: 100%;
      height: 175px;
      display: inline-block;
      background-position: center;
    }
  }

  .platform__info-content-close-container {
    height: 25px;
    margin-bottom: 0;

    a.platform__info-content-close {
      margin: 15px 15px 15px 0;
    }
  }
}

.platform__info-content {
  p,
  .platform__info-p {
    height: 265px;
    font-size: 15px;
    margin: 0 0 25px;
    padding-top: 75px;
  }
}

.mp {
  background-color: #c6e9fa;
  margin-right: 2%;

  .platform__logo {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368513203546-MP_logo.png');
  }

  .platform__info-content {
    background-color: #00a3da;
  }

  a.platform__docs {
    color: #fff;
    border: 1px solid #fff;
  }
}

.ms {
  background-color: #f8c3c9;

  .platform__logo {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368513203546-MS_logo.png');
  }

  .platform__info-content {
    background-color: #db344b;
  }

  a.platform__docs {
    color: #fff;
    border: 1px solid #fff;
  }
}

.mlp {
  background-color: #fff47e;

  .platform__logo {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368513203546-ML_logo_publicidad.png');
  }

  .platform__info-content {
    background-color: #ffe600;
  }

  p.platform__info-p {
    color: #333;
  }

  a.platform__docs {
    color: #333;
    border: 1px solid #333;
  }
}

.platform__transition-mob {
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.meli-plat {
  .platform__transition-mob.open-meli {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368432000020-open-meli.png');
  }
}

.platform__transition-mob.open-mp {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368432000020-open-mp.png');
}

.platform__transition-mob.open-ms {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368432000020-open-ms.png');
}

.platform__transition-mob.open-mlp {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/368432000020-open-meli.png');
}

/* Countries
    --------------------------------------------------------------- */

.section-content.countries {
  background-color: #fef9cf;
  width: 100%;
  max-width: 100%;
  padding: 40px 15px;

  .countries__img-container {
    display: none;
  }
}

.countries__list {
  list-style: none;
  padding: 0;
}

.countries__list-item {
  border-bottom: 1px solid #2d3277;
  display: table;
  line-height: 70px;
  padding-top: 20px;
  padding-bottom: 24px;
  width: 100%;

  .countries__name,
  .countries__products {
    display: table-cell;
    font-size: 16px;
  }
}

.countries__list-item:last-child {
  border-bottom: 0;
}

.countries__products {
  text-align: right;

  span {
    border-radius: 100%;
    display: inline-block;
    height: 20px;
    margin: 0 2px;
    text-align: left;
    width: 20px;
  }

  .products__meli {
    background-color: #ffe600;
  }

  .products__mp {
    background-color: #25a8e7;
  }

  .products__ms {
    background-color: #f23b52;
  }

  .products__me {
    background-color: #7ab642;
  }

  .products__kpl {
    background-color: #d8d8d8;
  }
}

/**
  Media Query for smaller devices
*/
@media (width >= 320px) {
  .hide-desktop {
    display: none;
  }

  .nav__lang-flag-mobile-container {
    .nav__lang-flag-mobile {
      height: 28px;
    }
  }

  .section-content.banner-content::after {
    left: 73%;
    margin-left: -115px;
    border-top: solid 40px #eee;
    border-left: solid 40px transparent;
    border-right: solid 40px transparent;
  }

  .nav-header {
    .nav__logo-container {
      .oop-logo {
        margin-top: 10px;
        width: 200px;
      }
    }
  }

  .banner-content__main-container {
    .banner-content_img-container {
      height: 262px;
      margin-bottom: 0;
      padding: 0;
    }

    .banner-content_img {
      background-size: cover;
      height: 90%;
    }
  }

  .platform__links-container {
    .platform__links-doc {
      width: 200px;
    }
  }

  .section-content.countries {
    padding-bottom: 1px;
  }

  .meli-plat__subrpoducts-container {
    .meli_me {
      height: 62px;
      margin: 0 1px;
      min-height: 62px;
    }
  }

  .meli-plat__subrpoducts-container {
    span {
      height: 80px;
      width: 85px;
    }
  }

  .meli-plat {
    .platform__info-p {
      margin-bottom: 60px;
    }
  }

  .ms {
    .platform__info-content {
      p,
      .platform__info-p {
        padding-top: 0;
      }
    }
  }

  .meli-plat__subrpoducts-container {
    margin-bottom: 20px;
  }

  .nav__lang-flag-mobile-container {
    margin-left: 0;
  }
}

@media (width >= 375px) {
  .nav__lang-flag-mobile-container {
    .nav__lang-flag-mobile {
      height: 50%;
    }
  }

  .nav__lang-flag-mobile-container {
    margin-left: 14%;
  }

  .platform__info-content {
    p,
    .platform__info-p {
      height: 265px;
      font-size: 15px;
      margin: 0 0 25px;
      padding-top: 75px;
    }
  }

  .ms {
    .platform__info-content {
      p,
      .platform__info-p {
        padding-top: 30px;
      }
    }
  }

  .meli-plat {
    .platform__info-content,
    .platform__info-content {
      .platform__info-p,
      p {
        height: 305px;
      }
    }
  }
}

@media (width >= 414px) {
  .meli-plat__subrpoducts-container {
    .meli_clasi {
      margin-left: 20px;
    }

    .meli_me {
      margin: 0 16px;
    }

    .meli_markplc {
      margin-right: 20px;
    }
  }

  .nav__lang-flag-mobile-container {
    .nav__lang-flag-mobile {
      height: 27px;
    }
  }

  .nav__lang-flag-mobile-container {
    margin-left: 22%;
  }
}

@media (width >= 480px) {
  .meli-plat__subrpoducts-container-wrapper {
    padding: 0 10%;
  }
}

// TABS
$tabs: 6;
$color: #242b36;
$brand: #3483fa;

*,
::before,
::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(#000, 0);
}

input[name='tabs'] {
  display: none;
  @for $i from 1 through $tabs {
    // sass-lint:disable no-ids
    &#tab-#{$i}:checked {
      ~ .content .tab:nth-of-type(#{$i}) {
        display: inline-block;
      }
      ~ .tabs label:nth-child(#{$i}) {
        color: #242b36;

        &::after {
          width: calc(100% - 40px);
          transition: 0.3s;
        }
      }
    }
  }
}

.tabs {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  white-space: nowrap;

  label {
    display: inline-block;
    padding: 18px;
    position: relative;
    color: lighten($color, 60%);
    font-weight: 700;
    font-size: 18-px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    cursor: pointer;

    &::after {
      content: '';
      background: $brand;
      width: 0;
      height: 2px;
      position: absolute;
      bottom: 0;
      top: 47px;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.1s;
    }
  }
}

.andes-table__body {
  .andes-checkbox__input {
    appearance: auto;
    display: inline;
    accent-color: $andes-blue-500;
  }
}

@media (width <= 767px) {
  .andes-table__body {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
  }

  .andes-table__column::before {
    content: none;
  }

  .andes-table__header,
  .andes-table__column {
    display: table-cell;
    margin: 0;
    text-align: center;
    white-space: normal;
    padding: 10px;
  }

  .andes-table {
    .andes-table__row {
      display: flex;
      flex-direction: column;
      max-width: inherit;
      word-wrap: break-word;

      .andes-table__column {
        padding: 20px 15px;
        word-wrap: break-word;
        max-width: 95vw;
        width: 100%;
        text-align: left;
      }
    }
  }
}

.container-tabs {
  background: #fff;
  width: 90%;
  border-radius: 3px;
  margin: 25px auto;
  overflow: hidden;

  .tab {
    display: flex;
    padding: 0 20px;

    .andes-table__column {
      white-space: unset;
    }

    label.andes-checkbox.demo-checkbox.andes-checkbox--default {
      pointer-events: none;
      vertical-align: super;
    }

    @media (width <= 768px) {
      padding: 0;
    }
  }

  @media (width <= 768px) {
    width: 100%;
  }
}

.oc__section-banner {
  display: none;

  h2 {
    width: 598px;
    font-size: 44px;
    font-weight: 300;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: rgb(0 0 0 / 80%);
    margin: 0;
  }

  @media (width >= 768px) {
    display: flex;
    height: 150px;
    align-items: center;
    justify-content: center;
    margin-top: -25px;
  }
}

.oc__cards-container {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 64px;

  .andes-card {
    display: inline-block;
    width: 100%;
    max-width: 1126px;
    padding: 32px;
    margin-bottom: 16px;

    .andes-card__content {
      display: flex;
      justify-content: space-between;
      height: 100%;
      @media (width <= 1023px) {
        flex-direction: column;
      }
    }
    @media (width <= 1023px) {
      padding: 8px 0 12px;
    }
  }

  .oc__card-link {
    @media (width <= 1023px) {
      margin-top: 12px;
    }
  }
  @media (width <= 1023px) {
    padding: 0 22px 30px;
  }
}

.oc__card-squares-icon {
  position: relative;
  display: inline-block;
  height: 6px;
  width: 6px;
  border: 1px solid rgb(0 0 0 / 45%);
  background-color: #fff;
  z-index: 1;
  margin-right: 10px;

  &::before {
    content: '';
    position: absolute;
    height: 6px;
    width: 6px;
    border: 1px solid rgb(0 0 0 / 45%);
    background-color: #fff;
    z-index: 2;
    right: -4px;
    top: -4px;
  }

  &::after {
    content: '';
    position: absolute;
    height: 6px;
    width: 6px;
    border: 1px solid rgb(0 0 0 / 45%);
    background-color: #fff;
    z-index: 3;
    right: -7px;
    top: -7px;
  }
}

.oc__card-counter {
  display: inline;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: rgb(0 0 0 / 45%);
  margin: 0;
  margin-bottom: 6px;
}

.oc__card-title {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.25;
  color: rgb(0 0 0 / 80%);
  margin: 0;
  padding: 0;
  white-space: normal;
}

.oc__card-link {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  padding: 16px 24px;
  color: #3483fa;
}

.oc__card-arrow {
  height: 21px;
  width: auto;
  margin-left: 14px;
}

.oc__main-content-container {
  padding: 20px;
  overflow: hidden;
  @media (width <= 1023px) {
    padding-top: 0;
  }
}

.oc__main-content-wrapper {
  max-width: 1140px;
  margin: 0 auto;
}

.oc__andes-button {
  cursor: pointer;
  color: #fff !important;
  background-color: #3483fa;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  border-radius: 4px;
  padding: 16px 24px;
  transition: background-color 0.2s ease-out;
  text-align: center;

  &:hover {
    background-color: #1e6dff;
  }
  @media (width <= 767px) {
    display: inline-block;
    width: 100%;
  }
}

.oc__new-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px 24px;
  border: 1px solid #ededed;

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: rgb(0 0 0 / 80%);
  }
}

.oc__new-user-description {
  font-size: 14px;
  line-height: 1.43;
  font-weight: normal;
  color: rgb(0 0 0 / 80%);
  margin-bottom: 24px;
}

.oc__middle-page-title {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0 0 0 / 80%);
  padding: 0;
  margin-top: 48px;
  margin-bottom: 24px;
  @media (width >= 767px) {
    text-align: start;
    font-size: 32px;
    margin-top: 64px;
    margin-bottom: 88px;
  }
}

.oc__title-highlight {
  font-weight: bold;
  display: block;
  @media (width >= 767px) {
    display: inline;
  }
}

.oc__features-wrapper {
  @media (width >= 767px) {
    border: 1px solid #ededed;
    padding: 32px;
    position: relative;
    height: 230px;
  }
}

.oc__features-paragraph {
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: rgb(0 0 0 / 80%);
  margin-bottom: 24px;
  font-weight: normal;

  &.bold {
    font-weight: bold;

    span {
      display: block;
      @media (width >= 767px) {
        display: inline;
      }
    }
  }
  @media (width >= 767px) {
    font-size: 20px;
    line-height: 1.2;
    max-width: 488px;
    text-align: left;
  }
}

.oc__features-links {
  a {
    background-color: #f5f5f5;
    display: block;
    width: 100%;
    color: #3483fa;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
    border-radius: 6px;
    padding: 14px 16px;
    position: relative;

    b {
      @media (width >= 767px) {
        margin-left: 0.35em;
      }
    }

    img {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 4px;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(90deg);
      @media (width >= 767px) {
        position: static;
        margin: 0;
        margin-left: 16px;
      }
    }
    @media (width >= 767px) {
      display: flex;
      align-items: center;
      padding: 20px 24px;
      padding-right: 8px;
      font-size: 24px;
      margin-bottom: 24px;
      letter-spacing: 0.85px;
    }
  }
  @media (width >= 767px) {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: -30px;
    top: -24px;
    height: 100%;
    text-align: right;
  }
}

.oc__features-cloud {
  img {
    height: 66px;
    width: 170px;
    margin-top: -22px;
    margin-left: 20px;
    @media (width >= 767px) {
      margin-top: 0;
      margin-left: 0;
      height: 99px;
      width: 256px;
    }
  }
  @media (width >= 767px) {
    position: absolute;
    bottom: 165px;
    right: -38px;
  }
}

// herramientas
.oc__container-title {
  padding: 0;
  margin: 64px 0 32px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.25;
  width: 100%;
  max-width: 1126px;
  color: rgb(0 0 0 / 80%);
  @media (width <= 1023px) {
    margin: 32px 0;
    font-size: 20px;
  }
}

.sdk__image {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25;
  color: rgb(0 0 0 / 80%);
  display: flex;
  align-items: center;

  img {
    height: 64px;
    width: 64px;
    margin-right: 18px;
    @media (width <= 1023px) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
  @media (width <= 1023px) {
    flex-direction: column;
    justify-content: center;
  }
}

.sdk__text {
  display: flex;
  width: 50%;
  @media (width <= 1023px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
}

.sdk__score {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;

  p {
    font-size: 28px;
    font-weight: normal;
    line-height: 0.86;
    margin: 0;
    color: rgb(0 0 0 / 80%);

    + p {
      margin-left: 48px;
      @media (width <= 1023px) {
        margin-left: 0;
      }
    }

    small {
      font-size: 18px;
      @media (width <= 1023px) {
        font-size: 12px;
      }
    }

    span {
      display: block;
      font-size: 16px;
      line-height: 1.25;
      color: rgb(0 0 0 / 45%);
      @media (width <= 1023px) {
        font-size: 12px;
        line-height: 1.67;
      }
    }
    @media (width <= 1023px) {
      width: 50%;
      border: 1px solid #f5f5f5;
      padding: 8px 0 4px 24px;
      font-size: 20px;
      line-height: 1.2;
    }
  }
  @media (width <= 1023px) {
    width: 100%;
  }
}

.sdk__others-wrapper {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      border-radius: 100%;
      height: 64px;
      width: 64px;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.25;
      color: rgb(0 0 0 / 80%);
      margin-top: 8px;
    }
  }
  @media (width <= 1023px) {
    overflow-x: scroll;
    margin: 0 -20px 52px;
  }
}

.sdk__others-tools {
  display: flex;
  justify-content: space-between;
  height: 245px;

  > div {
    height: 100%;
    border: 1px solid #ededed;
    border-radius: 3px;
    padding: 42px 0 22px;
    @media (width <= 1023px) {
      margin: 0 auto 16px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 328px;

    p {
      font-size: 24px;
      line-height: 1.25;
      letter-spacing: normal;
      font-weight: normal;
      color: rgb(0 0 0 / 80%);
    }
    @media (width <= 1023px) {
      width: 100%;
      max-width: 278px;
    }
  }
  @media (width <= 1023px) {
    flex-direction: column;
    height: unset;
  }
}

// home-opensource section
.home-opensource {
  padding: 120px 0 46px;
  background-color: #fff;
  @media (width <= 767px) {
    padding: 50px 0 80px;
  }
}

.home-opensource__container {
  margin: 0 auto;
  max-width: 1144px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  @media (width <= 1023px) {
    flex-direction: column-reverse;
  }
}

.home-opensource__text {
  width: 488px;
  border-radius: 6px;
  background-color: #f5f5f5;
  padding: 30px;
  padding-bottom: 12px;
  position: relative;
  @media (width <= 1023px) {
    width: 100%;
    padding: 20px 15px 12px;
    margin: 0 auto;
    max-width: 278px;
  }
}

.home-opensource__text::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 41px;
  top: 27px;
  z-index: -1;
  background-color: red;
  background: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/333957857321-group-29-copy-2.png')
    no-repeat;
  background-position: right bottom;
}

.home-opensource__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.04;
  letter-spacing: normal;
  font-stretch: normal;
  margin-bottom: 4px;
  color: rgb(0 0 0 / 80%);
  @media (width <= 1023px) {
    font-size: 18px;
    line-height: 1.39;
    margin-top: 0;
  }
}

.home-opensource__description {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(0 0 0 / 80%);
  max-width: 333px;
  margin: 0;
  @media (width <= 1023px) {
    font-size: 14px;
  }
}

.home-opensource__link {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #3483fa !important;
  text-decoration: none;
  padding: 16px 24px;
  margin-left: -24px;
  display: inline-block;
}

.home-opensource__apps {
  position: relative;
  right: 40px;
  top: 8px;
  height: 140px;
  width: 314px;
  @media (width <= 1023px) {
    right: 0;
    width: 278px;
    height: auto;
    margin: 0 auto;
  }
}

.mesh-row__categories .nav__list input[type='checkbox']:checked + label + ul {
  max-height: max-content;
}

// tamplate newsletter

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col,
.col-6,
.col-lg-9,
.col-md-3,
.col-md-9,
.col-md-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.d-block {
  display: block;
}

.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.align-items-center {
  -ms-flex-align: center;
  align-items: center;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.my-4 {
  margin-top: 1.5rem;
}

.my-4 {
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 3rem;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem;
}

.py-3 {
  padding-top: 1rem;
}

.py-3 {
  padding-bottom: 1rem;
}

.px-5 {
  padding-right: 3rem;
}

.px-5 {
  padding-left: 3rem;
}

.text-center {
  text-align: center;
}

.font-italic {
  font-style: italic;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.transition_all,
.esc_custom a {
  transition: all 0.3s ease-in-out;
}

.esc_custom {
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
}

.esc_custom .esc__section-banner {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  height: 150px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff159;
  width: 100%;
  margin: -25px auto 0;
}

@media screen and (width >= 678px) and (width <= 767px) {
  .esc_custom .esc__section-banner {
    margin-top: -81px;
  }
}

@media screen and (width <= 677px) {
  .esc_custom .esc__section-banner {
    margin-top: -81px;
    height: auto;
    padding: 30px 20px;
  }
}

.esc_custom .esc__section-banner h2 {
  max-width: 598px;
  font-size: 44px;
  font-weight: 300;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0 0 0 / 80%);
  margin: 0;
  padding: 0;
}

@media screen and (width <= 677px) {
  .esc_custom .esc__section-banner h2 {
    font-size: 34px;
  }
}

.esc_custom .title__h2 {
  padding: 65px 0 0;
  margin: -65px 0 10px;
  font-size: 32px;
  color: rgb(0 0 0 / 80%);
  line-height: 1.25;
  font-weight: 400;
}

.esc_custom .title__h2 strong {
  font-weight: 600;
}

.esc_custom .title__h3 {
  padding: 0;
  margin: 0 0 10px;
  font-size: 24px;
  color: rgb(0 0 0 / 80%);
  line-height: 1.25;
  font-weight: 400;
}

.esc_custom .title__h3 strong {
  font-weight: 600;
}

.esc_custom .title__h5 {
  padding: 0;
  margin: 0 0 10px;
  font-size: 16px;
  color: rgb(0 0 0 / 80%);
  line-height: 1.8;
  font-weight: 400;
}

.esc_custom .title__h5 strong {
  font-weight: 600;
}

.esc_custom .title__p {
  color: rgb(0 0 0 / 80%);
  font-size: 20px;
  line-height: 1.6;
  margin: 0;
}

.esc_custom .title__p__small {
  color: rgb(0 0 0 / 80%);
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

.esc_custom section.buttons__links a {
  color: #3483fa;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
}

.esc_custom mark {
  color: rgb(0 0 0 / 80%);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  background: transparent;
  padding: 10px 20px;
  display: block;
  border-left-width: 4px;
  border-left-style: solid;
}

.esc_custom mark.marked__blue {
  border-left-color: #2974ff;
}

.esc_custom .wrapper p {
  color: rgb(0 0 0 / 80%);
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 160%;
}

.esc_custom a {
  font-size: 16px;
  color: #3483fa;
  text-decoration: none;
}

.esc_custom a:link,
.esc_custom a:visited {
  color: #3483fa;
}

.esc_custom a:hover {
  color: #2974ff;
}

.esc_custom .all_sites {
  display: block;
}

.esc_custom .all_sites a {
  color: #666;
  font-size: 13px;
  background-color: #e7e7e7;
  padding: 0 8px;
  display: inline-block;
  line-height: 24px;
}

.esc_custom .all_sites a:hover {
  background-color: #2974ff;
  color: #fff;
}

// Andes message
.andes-message.andes-message--neutral {
  background: #e6e6e6;
}

.andes-message.andes-message--highlight {
  background: #5c95ff;
}

.andes-message__title--neutral {
  color: #333;
}

.andes-message__text--highlight.site-carriers__message-text {
  color: #fff;
  font-weight: 400;
}

.andes-message__icon {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/300092402329-03-Icon-1x.png');
  transform: rotate(180deg);
}

// DPP styles

.hero {
  position: relative;
  margin-top: -25px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (width >= 678px) and (width <= 768px) {
    margin-top: -81px;
  }
}

.hero_image img {
  width: 100%;
  height: 400px;
}

.hero::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $black-045-opacity;
}

.hero__container {
  width: 780px;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
}

@media (width <= 768px) {
  .hero__container {
    max-width: inherit;
    width: 100%;
    padding: 0 28px 0 18px;
  }

  .hero__description {
    max-width: 95%;
  }
}

.hero__container::before {
  content: '';
  position: absolute;
  top: -30px;
  background-color: $yellow-100;
  width: 90px;
  height: 10px;
}

.hero__title {
  max-width: 400px;
  font-size: 32px;
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  color: $white;
  margin: 0 0 24px;
  margin-bottom: 16px;
}

.hero__description {
  max-width: 524px;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-align: left;
  color: $white;
  margin: 0;
}

.section__medals {
  border-top: 10px solid $yellow-100;
}

.section__medals .cta {
  margin-top: 32px;
}

.section-medals__content {
  max-width: 1036px;
  margin: 32px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 4px;
  height: 390px;
  align-items: center;
}

.section-medals__paragraphs-wrapper {
  max-width: 500px;
  padding: 0 16px;

  p {
    margin: 0;
    text-align: initial;
    font-size: 16px;
    line-height: 1.13;
    color: $black-08-opacity;
  }
}

.section-medals__paragraph-spacer {
  height: 22px;
}

.section-medals__paragraph-bold {
  font-weight: bold;
}

.section-medals__podium {
  width: 490px;
  height: 419px;

  img {
    width: 100%;
    height: 100%;
  }
  @media (width <= 768px) {
    width: 100%;
    max-width: 311px;
    height: auto;
    margin-bottom: 26px;
  }
}

.section__benefits {
  background-color: $gray-885;
  padding: 48px 18px;
  position: relative;
  text-align: center;
}

.section__benefits .section__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 16px;
  color: $black-08-opacity;
}

.section__benefits .section__description {
  font-size: 16px;
  line-height: 1.13;
  color: $black-08-opacity;
}

.section__benefits .section__content {
  display: flex;
  flex-direction: column;
  max-width: 940px;
  margin: 32px auto;
}

.section__benefits .andes-card {
  box-sizing: border-box;
  padding: 26px 24px;
  width: 100%;
  margin-bottom: 20px;
}

.section__benefits .card__title {
  margin: 12px 0;
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  color: $black-08-opacity;
}

.section__content {
  h3 {
    padding-top: 0;
  }
}

.section__benefits .card__description {
  font-size: 14px;
  line-height: 1.3;
  margin-top: 4px;
  color: $black-08-opacity;
}

.section__benefits .card__image {
  margin: 0;
}

.section__benefits::before,
.section__benefits::after {
  content: '';
  background: url('../../assets/images/mask_black.png');
  position: absolute;
  height: 145px;
  top: 50%;
  margin-top: -72px;
}

.section__banner {
  background-color: $yellow-200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 20px;
}

.banner__ctas {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 140px;
}

.cta__button,
.andes-button {
  background-color: rgb(65 137 230 / 15%);
  border: none;
  font-weight: 600;
  padding: 10px;
  width: 320px;
}

.cta__button .andes-button__content {
  color: #3483fa;
  text-align: center;
}

.cta__button .andes-button__text {
  font-weight: bold;
  float: none;
}

.banner__ctas .cta .andes-button:link {
  padding: 10px;
  line-height: normal;
}

.banner-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  margin: 0;
  color: $black-08-opacity;
}

.banner-description {
  margin: 16px 0 24px;
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: $black-08-opacity;
}

.mesh-row__content {
  padding-bottom: 20px;
}

.mesh-row__index_fixed {
  position: fixed;
  top: 0;
  z-index: 99;
  margin-top: 47px;

  button {
    width: 250px;
  }
}

.mesh-row__index {
  button {
    width: 250px;
  }
}

.mesh-list__index {
  height: 100%;
  max-height: 80vh;
  overflow-y: auto;
}

.mesh-row__index-link-options {
  width: 250px;
}

.mesh-row__index-btn {
  background-color: #3483fa;
  color: #fff;
}

.api-docs-breadcrumbs-container {
  margin: 30px 0 80px;
  width: 100%;
}

.api-docs-breadcrumbs-container.requisitos {
  margin: 10px 0 40px;
}

.api-docs-breadcrumbs {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1200px;
  display: flex;

  li {
    font-size: 14px;
    line-height: 1.29;
    color: $gray-80;
    display: flex;

    + li::before {
      content: '';
      margin: 1px 6px 0;
      width: 16px;
      height: 16px;
      display: inline-block;
      background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/partner-center/306394536407-16-px-3x.png');
      background-size: cover;
      background-repeat: no-repeat;
    }

    a {
      font-weight: 600;
      color: $blue-100;
      text-decoration: none;
    }
  }
}
