@import '~@andes/breadcrumb/index';

@import 'index__large';

@media (width <= 1000px) {
  @import 'index__small';
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
