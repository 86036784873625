body {
  position: relative;
}

.community {
  text-align: center;
  padding: 50px 0 100px;
}

.community__title {
  margin: 0;
  padding-left: 80px;
  padding-top: 10px;
  display: inline-block;
}

.community-icons__logo {
  will-change: transform;
}

.community-icons__logo:hover {
  transform: scale(1.3);
  transition: transform 0.5s ease;
}
