.scroll-top {
  width: 42px;
  height: 42px;
  position: fixed;
  bottom: 86px;
  left: 32px;
  border-radius: 50%;
  background: #3483fa;
  color: #fff;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
  transition: opacity 500ms ease;
  opacity: 0;
}

.scroll-top svg {
  fill: #fff;
  height: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -10px;
  margin-left: -8px;
}
