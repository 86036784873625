.modal__content {
  padding: 40px;
}

.community {
  background-color: #f5f5f5;
  padding: 30px 0 15px;
  margin-top: 30px;
}

.community__title {
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 0.9px;
  text-align: center;
  color: #000;
  margin: 20px 0;
  font-weight: normal;
}

.community-icons__logo {
  width: 17%;

  a {
    cursor: pointer;
  }

  @media (width <= 767px) {
    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.community-icons__insta {
  width: 48px;
  height: 48px;

  @media (width <= 767px) {
    height: 30px;
    width: 30px;
  }
}
