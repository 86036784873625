.nav-footer {
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;
  padding: 0 5%;
  height: 60px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: unset;

  .user-name {
    display: none;
  }
}

.nav-footer-wrapper {
  max-width: 1450px;
  margin: 0 auto;
}

.nav-footer-copyright {
  display: inline-block;
  text-align: left;
  border-bottom: none;
  padding: 0;
  width: unset;
  position: inherit;
}

.nav-footer-navigation {
  display: inline-block;
  padding: 0;
  margin: -1px 16px;
  text-align: left;
}

.nav-footer-navigation__list {
  margin: 0;
}

.nav-footer-navigation__items {
  float: left;
  padding: 0 10px;
  list-style: none;
  margin-top: 20px;

  a {
    padding-right: 0;
  }
}

.nav-footer-language {
  float: right;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 0;
  padding: 0;
  border: none;

  // Para que el selector se despliegue hacia arriba
  .andes-dropdown--small .andes-dropdown__popover {
    top: -5.7em !important;
    min-width: 7.375em;
    left: -0.6em;
  }
}

.nav-footer-language__link {
  margin-right: 45px;
}
