.supportcongrats__header_background {
  background: #f73;
  height: 287px;
  display: flex;
  margin-bottom: 0 !important;
  margin-top: 55px !important;
  top: 0;
  width: 100%;
}

.supportnotcompliant__app-congrats {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);
  height: 130px;
  left: 52%;
  margin-bottom: 0 !important;
  margin-left: -210px;
  margin-top: -195px;
  padding: 25px;
  position: absolute;
  top: 65%;
  transform: translate(0%, 0%);
  width: 368px;
}

.start_button__app-congrats {
  margin-bottom: 40px;
}

.start_button__app {
  left: 50% !important;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 50% !important;
}

.supportcongrats__app {
  height: 175px;
  justify-content: center;
  left: 51%;
  margin-bottom: 0 !important;
  margin-left: -243px;
  margin-top: -268px;
  position: absolute;
  top: 40%;
  width: 480px;
}

.supportcongrats__app > h2 {
  color: #ffff;
  font-weight: normal;
  text-align: center;
  margin: 0;
  padding: 0 !important;
}

.supportcongrats__app > svg {
  margin-bottom: 3%;
  margin-left: 8%;
}

.supportnotcompliant_body__app-congrats {
  text-align: center;
  padding-top: 0;
  padding-bottom: 20px;
  width: 298px;
  height: 40px;

  p {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
  }
}
