//two-column styles
.header-section__two-column {
  margin-top: 0;

  .header-texts__two-column {
    padding-left: 20px;

    h1 {
      height: auto;
      margin: 39px 0 12px;
      font-size: 28px;
      line-height: 35px;
    }
  }

  .header-img__two-column {
    display: none;
  }

  @media (width <= 1000px) and (width >= 768px) {
    margin-top: 56px !important;
  }
}

.mesh-col-l-12 {
  padding: 0;
  display: block;
}

.mesh-col-l-3__title {
  display: none;
}

.mesh-col-l-3 {
  margin: 0 0 16px;
  padding: 0;
  width: 100%;
  border-radius: 0;

  .nav__list {
    margin: 0;
    padding: 16px 0 0 20px;
  }

  .list-selected,
  .list-not-selected {
    .list-menu__dropdown-label {
      display: flex;
      flex-direction: row-reverse;
      justify-content: start;
      align-items: baseline;
      gap: 8px;
      padding: 0 0 10px;
    }
  }

  .list-not-selected {
    .list-menu__dropdown-label {
      .fa {
        transform: rotate(90deg);
      }
    }
  }

  .fa {
    color: $black-025-opacity;
  }

  .group-list {
    border-bottom: none;
    padding: 0;
  }

  .list-menu__input-checkbox {
    width: 100%;
    height: $andes-spacing-32;
    position: absolute;
    opacity: 0;
  }
}

.mesh-col-l-7 {
  width: 100%;
  margin: 0 0 66px;
  padding: 24px 20px 40px;

  .inner-content {
    h2:first-of-type {
      display: none;
    }

    h2 {
      display: block;
    }
  }
}

.two-column-breadcrumbs-container {
  .andes-breadcrumb {
    display: flex;
    flex-direction: row;
    width: auto;
    margin: 0 0 38px;
    padding: 0;
    font-weight: 600;
    font-size: $font-size-s;
    line-height: 12px;

    :visited {
      color: $blue-100;
    }

    .andes-breadcrumb__item li {
      display: flex;
      gap: 10px;
    }
  }
}

.two-column__btn-content {
  position: fixed;
  right: 0;
  top: 371px;
}

.two-column__btn-button {
  margin: 0;
}

.two-column__btn-content-open {
  border-radius: 6px 0 0 6px;
}

.two-column__btn-content-fixed,
.two-column__btn-content-closed-fixed {
  right: 0;
  margin: 0;
}

.two-column__btn-content-closed-fixed {
  border-radius: 6px 0 0 6px;
}

.nav__list > div > li > label {
  display: flex !important;
}

.two-column__btn-content-closed {
  position: fixed;
  right: 0;
  top: 371px;
  border-radius: 6px 0 0 6px;
  margin: 0;
}

.btn-list-container {
  overflow: scroll;
  height: calc(100vh - 174px);

  &::-webkit-behavior {
    background: $gray-1000;
  }

  .andes-list {
    .andes-list__item--size-medium span {
      font-weight: 400;
      font-size: $font-size-xxsmall;
      line-height: 15px;
    }
  }
}

.doc-cross__content {
  flex-direction: column;
  height: 109px;
  padding: 16px;
}

.doc-cross__text {
  p {
    margin: 0 0 0 16px;
    padding: 0 0 0 16px;
  }
}

.doc-cross__buIcons {
  margin-top: 17px;
}

.doc-cross__banner-ML,
.doc-cross__banner-MS,
.doc-cross__banner-MP,
.doc-cross__banner-ME {
  margin: 0;
  width: 48.98px;
  height: 23.23px;
}
