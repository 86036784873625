// sass-lint:disable no-ids
.nav-header {
  background-color: #fff;
  height: 56px;
  box-shadow: none;
  display: block;

  .nav-bounds.nav-bounds-with-cart #nav-header-menu {
    margin-right: 0;
  }

  .nav-bounds {
    padding: initial;
  }

  .nav-bounds-large .nav-logo {
    text-indent: 147px;
    width: 140px;
    overflow: visible;

    span {
      padding-top: 15px;
      float: left;
      font-weight: bold;
      font-size: 18px;
      width: 160px;
    }
  }

  #nav-header-menu {
    background-color: #fff;
    display: inline-block;

    &::before {
      border-bottom-color: #fff;
    }
  }

  .categories-list {
    display: inline-block;
    padding: 0 20px 0 0;
    border: 0;
  }

  #nav-header-menu a {
    padding: 0 9px;
  }

  .nav-bounds-with-cart .nav-search {
    left: 200px;
    right: 70px;
  }

  .nav-header-menu-wrapper {
    padding: 0 15px 0 70px;

    .nav-search {
      width: calc(100% - 860px);
      display: inline-block;
      overflow: inherit;
    }
  }
}

.nav-header.nav-header-sticky {
  position: fixed;
  z-index: 999;
  top: 0;
}

.nav-bounds-with-cart .nav-search .nav-search-btn {
  top: 8px;
  right: 6px;
  height: 38px;
  color: #d2d2d2;
  background-color: unset;
}

// Caso especial de bug entre esas resoluciones
@media (width >= 768px) and (width <= 950px) {
  .nav-header .categories-list {
    padding: 0;
  }

  #nav-header .nav-header-menu a {
    padding: 0 5px;
  }

  .nav-header .nav-header-menu-wrapper .nav-search {
    width: calc(100% - 675px);
  }
}

.mobile-only {
  &.mobile-only {
    display: none !important;
  }
}

.desktop-only {
  &.desktop-only {
    display: flex !important;
  }
}

.nav-header .nav-header-categories-wrapper {
  border: 0;
  display: inline-block;
}
