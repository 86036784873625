.nav-footer {
  background-color: #fff;
  border-bottom: 1px solid #dcdcdc;
  display: block;

  .user-name {
    display: block;
    width: 100%;
    font-size: 12px;
    text-align: center;
    color: #999;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    border-top: 1px solid rgb(0 0 0 / 10%);
    border-bottom: 1px solid rgb(0 0 0 / 10%);

    &.with-padding {
      padding: 20px 0;
    }

    span {
      color: #333;
    }

    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0;
      padding: 0;

      li {
        padding: 20px 0;
        list-style: none;
        flex: 1;
      }
    }
  }

  a.nav-footer-language__link {
    color: #333;
  }
}

.nav-footer-copyright {
  margin: 0 auto;
  display: block;
  text-align: center;
  border-bottom: solid 1px #ddd;
  padding: 16px 0;
  width: 100%;
}

.nav-footer-copyright__text {
  font-size: 14px;
  color: #333;
}

.nav-footer-navigation {
  display: block;
  margin: 0 auto;
  padding: 16px 0 34px;
  text-align: center;
}

.nav-footer-navigation__list {
  margin: 0 auto;
  padding: 0;
}

.nav-footer-navigation__items {
  padding: 0 10px;
  list-style: none;
  display: inline-block;

  @media (width <= 768px) {
    display: block;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.nav-footer-language {
  background-color: white;
  border: solid 1px rgb(0 0 0 / 10%);
  border-right: 0;
  border-left: 0;
  padding: 25px;

  .andes-dropdown--small {
    text-align: center;

    .andes-dropdown__popover {
      min-width: 7.375em;
      left: 8.4em;
      top: 0.03em !important;
    }
  }
}
