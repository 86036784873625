@import '../../styles/variables';
@import '../content/content';
@import '../../components/CommunityView/community';
@import './components/HeaderView/styles';
@import './components/FooterView/footer';

@import 'styles__small';

@media (width >= 768px) {
  @import 'styles__large';
}
