.supporthasbackoffice__app {
  border-radius: 6px;
  height: 530px;
  left: 50%;
  margin-bottom: 0 !important;
  margin-left: -243px;
  margin-top: -265px;
  position: absolute;
  top: 50%;
  transform: translate(0%, 0%);
  width: 430px;
}

.supporthasbackoffice__app > svg {
  margin-bottom: 5%;
  margin-left: 25%;
  margin-top: 5%;
}

.supporthasbackoffice_body__app {
  margin-block-end: 50px;
  transform: translate(0%, 0%);
}

.supporthasbackoffice_body__app > h2 {
  font-size: 18px !important;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0;
  padding-top: 0 !important;
  text-align: center;
}

.supporthasbackoffice_body__app > p {
  color: black;
  font-size: 13px !important;
  line-height: normal !important;
  margin-block-start: 1em !important;
  margin-left: 58px;
  margin-right: 50px;
}

.supporthasbackoffice_body__app > ol > li {
  list-style: auto !important;
  margin-left: 35px;
  margin-right: 50px;
}

.redirectml_button__app,
.redirectmp_button__app {
  left: 51%;
  margin: auto !important;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 75%;
}

.redirectmp_button__app {
  background-color: rgb(65 137 230 / 15%);
  color: #3483fa;
  margin-top: 55px !important;
}

.redirectmp_button__app:hover,
.redirectmp_button__app:active {
  background-color: rgb(65 137 230 / 15%);
  color: #3483fa;
}

.supporthasbackoffice_body__app > ol > li > svg {
  vertical-align: text-bottom;
}

.nav-header-user {
  margin-top: 10px !important;
}
