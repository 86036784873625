code[class*='language-'],
pre[class*='language-'] {
  color: #fff;
  background: none;
  text-shadow: 0 -0.1em 0.2em black;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  border-radius: 3px;
  tab-size: 4;
  hyphens: none;
}

@media print {
  code[class*='language-'],
  pre[class*='language-'] {
    text-shadow: none;
  }
}

pre[class*='language-'],
:not(pre) > code[class*='language-'] {
  background: rgb(0 0 0 / 80%);
}

/* Code blocks */
pre[class*='language-'] {
  padding: 1em;
  overflow: auto;
  max-height: 450px;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
  padding: 0.15em 0.2em 0.05em;
  border-radius: 0.3em;
  border: 0.13em solid hsl(30deg 20% 40%);
  box-shadow: 1px 1px 0.3em -0.1em black inset;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: hsl(30deg 20% 50%);
}

.token.punctuation {
  color: #e4185c;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.symbol {
  color: #fff;
}

.token.constant {
  color: #e4185c;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #aadb1e;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #fff;
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #fff;
}

.token.regex,
.token.important {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.deleted {
  color: red;
}
