.form-subscription {
  flex-direction: column;
  height: auto;
  margin-bottom: 0;

  .form-subscription__row {
    .form-subscription__wrapper {
      flex-direction: column;
      display: flex;
      align-items: center;
    }

    .form-subscription_newsletter {
      color: $black;
      text-align: center;
      margin: 0 0 15px;
    }

    .andes-form-control__control {
      margin-bottom: 15px;
      text-align: center;
    }
  }

  .form-subscription__container {
    margin-bottom: 5px;
  }
}

.form-subscription__oneline-wrapper {
  .andes-form-control
    .andes-form-control--textfield
    .andes-form-control--readonly {
    margin: 15px 0;
  }
}

.form-subscription__wrapper-img {
  padding: 15px;
}

.form-subscription__btn-group .andes-button {
  padding: 0;
  background-color: #3483fa;

  &:hover {
    background-color: #1e6dff;
  }
}

.form-subscription-notlogged__container {
  color: $white;
  font-size: 13px;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.form-subscription__oneline-wrapper {
  .andes-form-control--readonly .andes-form-control__field {
    padding-left: 16px;
    font-size: 12px;
    margin: 0;
    color: rgb(0 0 0 / 55%);
  }
}

.andes-form-control__bottom {
  span {
    text-align: center;
  }
}

.andes-form-control--textfield .andes-form-control__bottom {
  margin: 15px 0;
}

.form-subscription-notlogged__container {
  color: $black;
  text-align: center;
}

@media (width >= 1023px) {
  .form-subscription__oneline-wrapper {
    .andes-form-control__bottom {
      span {
        color: $white;
      }
    }
  }
}
