@import '~@andes/dropdown/index';
@import '~@andes/list/index';

@import './community__small';

@media (width >= 768px) {
  @import './community__large';
}

.community {
  .mesh-row {
    margin: 0 !important;
  }
}
