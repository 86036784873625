.form-subscription {
  display: flex;
  text-align: center;
  align-items: center;
  height: 208px;

  .form-subscription__row {
    .form-subscription__wrapper {
      flex-direction: row;
      display: flex;
      align-items: center;
    }

    .form-subscription_newsletter {
      color: $black;
      text-align: left;
      margin: 0 0 8px;
    }

    .andes-form-control__control {
      margin-bottom: 10px;
    }
  }
}

.form-subscription__wrapper,
.form-subscription__oneline-wrapper,
.form-subscription__row {
  .andes-form-control--textfield .andes-form-control__control {
    width: 326px;
    height: 32px;
  }
}

.form-subscription__wrapper {
  padding-top: 20px;
}

.andes-form-control--textfield.andes-form-control--readonly
  .andes-form-control__control {
  background-color: $white;
}

.andes-form-control__bottom {
  span {
    text-align: left;
  }
}

.andes-form-control--textfield .andes-form-control__bottom {
  margin: 0;
}

.form-subscription-notlogged__container {
  text-align: left;
}

.form-subscription__row {
  padding-top: 0;
}

.form-subscription__wrapper {
  flex-direction: row;
}
