@charset "UTF-8";

/*! * Navigation * @platform "mercadolibre" * @version 2.3.5 * @author MercadoLibre.com */
// sass-lint:disable no-ids
[role='main'],
body,
html {
  height: 100%;
}
@font-face {
  font-family: navigation;
  src: url('https://http2.mlstatic.com/ui/navigation/2.3.5/navigation.eot');
  src: url('https://http2.mlstatic.com/ui/navigation/2.3.5/navigation.eot#iefix')
      format('embedded-opentype'),
    url('https://http2.mlstatic.com/ui/navigation/2.3.5/navigation.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/navigation/2.3.5/navigation.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/navigation/2.3.5/navigation.ttf')
      format('truetype'),
    url('https://http2.mlstatic.com/ui/navigation/2.3.5/navigation.svg#navigation')
      format('svg');
  font-weight: 400;
  font-style: normal;
}

[class*=' nav-icon-'],
[class^='nav-icon-'] {
  font-style: normal;
}

[class*=' nav-icon-']::before,
[class^='nav-icon-']::before {
  display: inline-block;
  font-variant: normal;
  margin: 0;
  speak: none;
  text-align: center;
  width: 1em;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: navigation;
}

.nav-header .nav-header-btn::before,
.nav-icon-back-o::before {
  content: '\EA01';
}

#nav-header-menu a.option-bookmarks::after,
.nav-icon-bookmarks-o::before {
  content: '\EA02';
}

.nav-icon-bookmarks::before {
  content: '\EA03';
}

.nav-icon-chevron-right-o::before {
  content: '\EA04';
}

.nav-icon-chevron-right::before {
  content: '\EA05';
}

.nav-icon-chevron-up-o::before {
  content: '\EA06';
}

.nav-icon-chevron-up::before {
  content: '\EA07';
}

.nav-icon-close::before {
  content: '\EA08';
}

.nav-icon-facebook::before {
  content: '\EA09';
}

.nav-icon-gplus::before {
  content: '\EA0A';
}

#nav-header-menu a.option-help::after,
.nav-icon-help-o::before {
  content: '\EA0B';
}

.nav-icon-help::before {
  content: '\EA0C';
}

.nav-icon-instagram::before {
  content: '\EA0D';
}

#nav-header-menu a.option-login::after,
.nav-icon-login-o::before {
  content: '\EA0E';
}

#nav-header-menu a.option-logout::after,
.nav-icon-logout-o::before {
  content: '';
}

.nav-icon-logout::before {
  content: '\EA10';
}

#nav-header-menu a.option-notifications::after,
.nav-icon-notifications-o::before {
  content: '\EA11';
}

.nav-icon-notifications::before {
  content: '\EA12';
}

.nav-icon-official-store-o::before {
  content: '\EA13';
}

#nav-header-menu a.option-register::after,
.nav-icon-register-o::before {
  content: '\EA14';
}

.nav-icon-search-o::before {
  content: '\EA15';
}

.nav-icon-search::before {
  content: '\EA16';
}

#nav-header-menu a.option-sell::after,
.nav-icon-sell-o::before {
  content: '\EA17';
}

.nav-icon-time-o::before {
  content: '\EA18';
}

.nav-icon-twitter::before {
  content: '\EA19';
}

#nav-header-menu a.option-my-account::after,
.nav-icon-user-o::before {
  content: '';
}

.nav-icon-user::before {
  content: '\EA1B';
}

.nav-icon-youtube::before {
  content: '\EA1C';
}

.nav-icon-close-o::before {
  content: '\EA1D';
}

.nav-icon-bookmarks-medium::before {
  content: '\EA1E';
}

.nav-icon-cart-empty-medium::before {
  content: '\EA1F';
}

.nav-icon-phone::before {
  content: '\EA20';
}

.nav-cart.nav-cart-empty .nav-icon-cart::before,
.nav-icon-cart-empty-small::before {
  content: '\EA21';
}

.nav-icon-cart-full-medium::before {
  content: '\EA22';
}

.nav-cart.nav-cart-full .nav-icon-cart::before,
.nav-icon-cart-full-small::before {
  content: '\EA23';
}

.nav-icon-help-medium::before {
  content: '\EA24';
}

.nav-icon-notifications-medium::before {
  content: '\EA25';
}

.nav-icon-user-medium::before {
  content: '\EA26';
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  border-collapse: collapse;
  display: table;
  background-color: #fff;
  font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica,
    Roboto, Arial, sans-serif, sans-serif;
  font-size: 14px;
  table-layout: fixed;
}

.nav-footer,
.nav-footer-access,
.nav-header {
  font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica,
    Roboto, Arial, sans-serif;
}

.nav-footer,
.nav-header,
[role='main'] {
  display: table-row;
  width: 100%;
}

[role='main'] .nav-bounds {
  box-sizing: border-box;
}

[role='main'] .nav-bounds[class*='ch-box-'] {
  border: none;
  background-color: transparent;
}

.nav-bounds {
  display: block;
  padding: 0 10px;
  margin: 0 auto;
}

#nav-header-user-switch,
.nav-footer-access,
.nav-footer-navigation,
.nav-footer-secondaryinfo,
.nav-header-user-layer a:last-child,
[for='nav-header-user-switch'] {
  display: none;
}

.nav-header {
  background-color: #ffdb15;
  color: #333;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: default;
  border-bottom: 0 solid #fff;
}

.nav-header .nav-bounds {
  position: relative;
  padding: 50px 0 0;
}

.nav-header,
.nav-header *,
.nav-header ::after,
.nav-header ::before {
  box-sizing: border-box;
}

.nav-header.nav-header-sticky {
  position: fixed;
  z-index: 900;
}

.nav-header.nav-header-sticky + main > .nav-bounds,
.nav-header.nav-header-sticky + main > .nav-main-content,
.nav-header.nav-header-sticky ~ main > .nav-bounds,
.nav-header.nav-header-sticky ~ main > .nav-main-content {
  padding-top: 50px;
}

[for='nav-header-menu-switch'] {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 45px;
  cursor: pointer;
}

[for='nav-header-menu-switch']:focus {
  outline: 0;
}

#nav-header-menu-switch {
  display: none;
}

#nav-header-menu-switch:checked + .nav-header-menu-wrapper #nav-header-menu {
  display: flex;
  flex-direction: column-reverse;
}

.hamburger-bottom-bread,
.hamburger-patty,
.hamburger-top-bread {
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background-color: #333;
  top: 50%;
  border-radius: 0;
  transition: all 0.1s ease-out;
  left: 12.5px;
}

#nav-header-menu::after,
#nav-header-menu::before {
  border-style: solid;
  border-color: transparent;
  position: absolute;
  bottom: 100%;
  transform: translateY(1px);
  content: '';
}

#nav-header-menu {
  background-color: #fff;
  display: none;
  position: relative;
}

#nav-header-menu a {
  display: block;
  font-size: 16px;
  text-decoration: none;
  color: #333;
  position: relative;
}

#nav-header-menu a [class*=' nav-icon-']::before,
#nav-header-menu a [class^='nav-icon-']::before {
  display: none;
}

#nav-header-menu a:first-child {
  border-top-color: #fff;
}

#nav-header-menu a::after {
  position: unset;
  top: 0;
  right: 0;
  display: block;
  font-family: navigation;
  color: #404040;
  content: '';
}

.nav-icon-close::before,
.nav-icon-search::before,
.nav-logo {
  display: inline-block;
}

#nav-header-menu::before {
  border-bottom-color: #fff;
  border-width: 8px;
  right: 14.5px;
  pointer-events: none;
}

#nav-header-menu::after {
  border-width: 7px;
  border-bottom-color: #fff;
  right: 15.5px;
  pointer-events: none;
}

.nav-logo {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/230734061556-Mercado-Libre--2-.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 28px;
  overflow: hidden;
  text-indent: -999px;
  width: 39px;
  top: 11px;
  position: absolute;
  left: 10px;
}
@media (min-device-pixel-ratio: 2),
  (resolution >= 192dpi),
  (resolution >= 2dppx) {
  .nav-logo {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/230734061556-Mercado-Libre--2-.png');
    background-size: contain;
  }

  [data-country='BR'] .nav-logo,
  [data-country='PT'] .nav-logo,
  .nav-logo[href$='pt_br'] {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/191761039894-Mercado-Libre.png');
    background-size: contain;
  }
}

.nav-search {
  position: absolute;
  left: 59px;
  top: 0;
  right: 45px;
  height: 50px;
  padding: 8px 0;
}

.nav-bounds-with-cart .nav-search {
  right: 94px;
}

input[type='text'].nav-search-input {
  color: #333;
  font-size: 16px;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #fff;
  padding: 6px 6px 6px 35px;
  box-shadow: none;
  font-family: inherit;
}

input[type='text'].nav-search-input:focus {
  box-shadow: 0 0 5px rgb(117 127 16 / 20%);
  padding: 6px 6px 6px 35px;
  outline: 0;
}

input[type='text'].nav-search-input.ch-autocomplete-loading {
  background-position: right 30px center;
}

button.nav-search-btn,
button.nav-search-btn:focus {
  position: absolute;
  top: 0;
  height: 50px;
  padding: 0;
  width: 36px;
  background: 0 0;
  border: none;
  font-size: 22px;
  color: #666;
  line-height: 1em;
  outline: 0;
}

.nav-icon-close span,
.nav-icon-search span {
  display: none;
}

.nav-footer {
  background-color: #eee;
  color: #999;
  font-size: 13px;
  overflow: hidden;
}

.nav-footer,
.nav-footer *,
.nav-footer ::after,
.nav-footer ::before {
  box-sizing: border-box;
}

.nav-footer a,
.nav-footer a:active,
.nav-footer a:link,
.nav-footer a:visited {
  color: #3483fa;
  text-decoration: none;
}

.nav-footer .nav-footer-change-device,
.nav-footer .nav-footer-change-device:active,
.nav-footer .nav-footer-change-device:link,
.nav-footer .nav-footer-change-device:visited {
  float: right;
  display: inline-block;
  line-height: 14px;
  color: #666;
}

.nav-footer-primaryinfo {
  margin: 0 0 15px;
  overflow: hidden;
  border-top: 0.5px solid #ddd;
  padding-top: 15px;
  font-size: 12px;
}

.nav-footer-copyright {
  font-size: inherit;
  display: inline-block;
  color: #666;
  vertical-align: top;
  width: 60%;
  line-height: 14px;
}

.nav-footer-user {
  padding: 25px 12px 20px;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
  font-size: 0;
}

.nav-footer-user .nav-footer-login,
.nav-footer-user .nav-footer-registration {
  font-size: 13px;
  line-height: 20px;
  display: inline-block;
}

.nav-footer-user .nav-footer-login {
  padding-right: 0.7em;
  border-right: 0.5px solid #ddd;
}

.nav-footer-user .nav-footer-registration {
  padding-left: 0.7em;
}

.nav-footer-user strong {
  font-weight: 400;
  color: #666;
  display: inline-block;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}

.nav-footer-user.logged {
  font-size: 13px;
}

.nav-footer-user.logged > a {
  margin-left: 7.5px;
}

.nav-footer-downloadapp-wrapper {
  display: none;
}

.nav-footer-downloadapp-banner {
  display: block;
  margin-top: 32px;
  text-align: center;
  background-color: #ffdb08;
  padding: 0 15px;
}

.nav-footer-downloadapp-banner a.nav-footer-downloadapp {
  font-size: 11px;
  vertical-align: middle;
  color: #666;
  padding-top: 0;
  display: inline-block;
}

.nav-footer-downloadapp-banner a.nav-footer-downloadapp:active,
.nav-footer-downloadapp-banner a.nav-footer-downloadapp:link,
.nav-footer-downloadapp-banner a.nav-footer-downloadapp:visited {
  color: #666;
}

.nav-footer-downloadapp-banner .nav-icon.nav-icon-downloadapp {
  background: url('https://http2.mlstatic.com/ui/navigation/2.3.5/mercadolibre/49x64-download-icon.png')
    top center no-repeat;
  background-size: 49px 64px;
  display: inline-block;
  width: 49px;
  height: 64px;
  margin-top: -8px;
  margin-right: 10px;
  vertical-align: middle;
}

#nav-footer-access-switch,
.nav-header .nav-header-btn--no-arrow::before,
.nav-header-has-search-active button.nav-search-btn,
.nav-header-has-search-active button.nav-search-btn:focus,
button.nav-search-btn span,
button.nav-search-btn:focus span {
  display: none;
}
@media (min-device-pixel-ratio: 2),
  (resolution >= 2dppx),
  (resolution >= 192dpi) {
  .nav-footer-downloadapp-banner .nav-icon.nav-icon-downloadapp {
    background: url('https://http2.mlstatic.com/ui/navigation/2.3.5/mercadolibre/97x127-download-icon@2x.png');
    background-size: 49px 64px;
    width: 49px;
    height: 64px;
  }
}

.nav-footer-info-wrapper {
  padding: 0 10px;
}

input[type='text'].nav-search-input {
  background-color: rgb(255 255 255 / 70%);
  border: none;
  position: relative;
  box-sizing: border-box;
  z-index: 915;
}

input[type='text'].nav-search-input:focus {
  border: none;
}

.nav-header-has-search-active input[type='text'].nav-search-input:focus {
  color: #666;
  box-shadow: none;
}

input[type='text'].navinput::input-placeholder {
  color: rgb(21 21 21 / 60%);
}

input[type='text'].navinput:input-placeholder {
  color: rgb(21 21 21 / 60%);
}

input[type='text'].nav-search-input::placeholder {
  color: rgb(21 21 21 / 60%);
}

.nav-header-has-search-active input[type='text'].nav-search-input {
  padding-right: 45px;
}

button.nav-search-btn,
button.nav-search-btn:focus {
  left: 0;
  right: initial;
  z-index: 920;
}

.nav-search-btn .nav-icon-search {
  font-size: 19px;
}

.nav-search-btn .nav-icon-search::before {
  content: '\EA15';
  vertical-align: bottom;
}

.nav-header .nav-header-btn {
  position: absolute;
  top: 3px;
  left: 0;
  padding: 20px;
  text-indent: -200%;
  border: 0;
  box-shadow: none;
  background: 0 0;
}

.nav-header .nav-header-btn::before {
  font-family: navigation;
  font-size: 20px;
  line-height: 1;
  color: #333;
  position: absolute;
  left: 10px;
  top: 10px;
  text-indent: 0;
}

.nav-header .nav-header-btn--no-arrow {
  text-indent: 0;
  border: 1px solid rgb(0 0 0 / 15%);
  padding: 0 10px;
  left: 10px;
  top: 10px;
}

.nav-header .nav-cart {
  color: #333;
}

.nav-search {
  z-index: 910;
  will-change: left;
  transition: left 0.15s ease-out;
}

.nav-search::before {
  content: '';
  display: none;
  position: absolute;
  top: 0;
  left: -10px;
  right: -10px;
  height: 100%;
  background-color: #fff;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.15s ease-out;
}

.nav-header-has-search-active .nav-search::before {
  display: block;
}

.nav-header--is-enter .nav-search::before {
  opacity: 1;
}

.nav-header--is-leave .nav-search::before {
  opacity: 0;
}

.nav-header--is-enter .nav-search {
  left: 10px;
  right: 10px;
}

.nav-search .nav-category {
  z-index: 917;
}

.nav-search-clear-btn,
.nav-search-clear-btn:focus,
.nav-search-close-btn,
.nav-search-close-btn:focus {
  font-size: 22px;
  line-height: 1;
  color: #333;
  border: 0;
  background: 0 0;
  display: none;
  position: absolute;
  top: 0;
  outline: 0;
  z-index: 920;
  height: 50px;
  padding: 0;
  width: 36px;
}

.nav-header-has-search-active .nav-search-close-btn,
.nav-header-has-search-active .nav-search-close-btn:focus,
.nav-search--has-text .nav-search-clear-btn {
  display: block;
}

.nav-search-close-btn,
.nav-search-close-btn:focus {
  top: 1px;
  left: -4px;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.15s ease-out;
}

.nav-search-close-btn::before,
.nav-search-close-btn:focus::before {
  content: '\EA01';
  font-family: navigation;
}

.nav-header--is-enter .nav-search-close-btn,
.nav-header--is-enter .nav-search-close-btn:focus {
  opacity: 1;
}

.nav-header--is-leave .nav-search-close-btn,
.nav-header--is-leave .nav-search-close-btn:focus {
  opacity: 0;
}

.nav-search-clear-btn,
.nav-search-clear-btn:focus {
  right: -4px;
}

.nav-search-clear-btn::before {
  content: '\EA1D';
  font-family: navigation;
  vertical-align: bottom;
}

.nav-cart,
.nav-header--is-leave .nav-search-clear-btn {
  display: none;
}

.hamburger-top-bread {
  margin-top: -6.67px;
}

.hamburger-patty {
  margin-top: -0.67px;
}

.hamburger-bottom-bread {
  margin-top: 5.33px;
}

#nav-header-menu-switch:checked
  + .nav-header-menu-wrapper
  .hamburger-top-bread {
  transform: translate(0, 6px) rotate(40deg);
}

#nav-header-menu-switch:checked + .nav-header-menu-wrapper .hamburger-patty {
  transform: scale(0, 0);
}

#nav-header-menu-switch:checked
  + .nav-header-menu-wrapper
  .hamburger-bottom-bread {
  transform: translate(0, -6px) rotate(-40deg);
}

#nav-header-menu {
  padding: 16px 0;
}

#nav-header-menu a {
  padding: 0 16px 0 72px;
  height: 50px;
  line-height: 50px;
  border: none;
}

#nav-header-menu a.bookmarks-widget,
#nav-header-menu a.notifications-widget,
#nav-header-menu a.option-help,
#nav-header-menu a.option-register {
  border-left: none;
}

#nav-header-menu a::after {
  left: 0;
  font-size: 24px;
}

.nav-bounds.nav-bounds-with-cart [for='nav-header-menu-switch'] {
  right: 45px;
}

.nav-bounds-with-cart #nav-header-menu::before {
  right: 26px;
}

.nav-bounds-with-cart #nav-header-menu::after {
  right: 27px;
}

.nav-bounds.nav-bounds-with-cart .nav-cart {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 45px;
  text-align: center;
}

.nav-bounds.nav-bounds-with-cart .nav-cart ::before {
  font-size: 18px;
  line-height: 50px;
}

.nav-bounds.nav-bounds-with-cart .nav-cart .nav-icon-cart {
  margin-left: -8px;
}

.nav-bounds.nav-bounds-with-cart .nav-cart .nav-icon-cart-quantity {
  position: absolute;
  right: 14px;
  top: 11px;
  font-size: 11px;
  line-height: 15px;
  width: 20px;
  text-align: center;
}

.nav-bounds.nav-bounds-with-cart
  .nav-cart.nav-cart-empty
  .nav-icon-cart-quantity {
  display: none;
}
@media (width >= 768px) {
  #nav-header-menu a::after,
  .nav-footer-user,
  .nav-header-btn,
  .nav-title,
  [class*=' nav-icon-'] span,
  [class^='nav-icon-'] span,
  [for='nav-header-menu-switch'] {
    display: none;
  }

  [class*=' nav-icon-']::before,
  [class^='nav-icon-']::before {
    display: inline-block;
  }

  body,
  html {
    font-size: 13px;
    background-color: #fff;
  }

  .nav-bounds {
    max-width: 1220px;
  }

  .nav-header {
    background-image: none;
    height: 54px;
    font-size: 13px;
    font-weight: 400;
  }

  .nav-header .nav-bounds {
    padding: 0 0 0 220px;
    height: 54px;
  }

  .nav-header :focus {
    outline: 0;
  }

  .nav-header.nav-header-sticky + main > .nav-bounds,
  .nav-header.nav-header-sticky + main > .nav-main-content,
  .nav-header.nav-header-sticky ~ main > .nav-bounds,
  .nav-header.nav-header-sticky ~ main > .nav-main-content {
    padding-top: 54px;
  }

  #nav-header-menu {
    display: block;
    font-size: 0;
    margin: 0 0 0 51px;
    background-color: transparent;
    float: right;
    height: 54px;
    padding: 0;
    white-space: nowrap;
  }

  #nav-header-menu a {
    display: inline;
    height: auto;
    border-top: none;
    color: #333;
    text-decoration: none;
    border-left: 1px solid #dccf50;
    padding: 1px 16px;
    line-height: 54px;
    font-size: 13px;
  }

  #nav-header-menu a [class*=' nav-icon-']::before,
  #nav-header-menu a [class^='nav-icon-']::before {
    display: inline-block;
  }

  #nav-header-menu a:hover,
  #nav-header-menu a:hover i::before {
    color: #000;
  }

  #nav-header-menu a.bookmarks-widget {
    border-left: none;
    padding-left: 0;
    display: inline;
  }

  #nav-header-menu i::before {
    color: #333;
    vertical-align: middle;
    font-size: 16px;
    text-shadow: 0 1px rgb(255 255 255 / 75%);
  }

  #nav-header-menu i.nav-icon-help::before {
    font-size: 17px;
  }

  #nav-header-menu i span {
    font-size: 14px;
  }

  #nav-header-menu .nav-icon-user {
    pointer-events: none;
  }

  #nav-header-menu .nav-icon-user::before {
    margin-left: 5px;
  }

  #nav-header-menu::after,
  #nav-header-menu::before,
  [data-country='BR'] #nav-header-menu .nav-icon-help::before {
    display: none;
  }

  .nav-header-user,
  [data-country='BR'] #nav-header-menu .nav-icon-help span {
    display: inline-block;
  }

  .nav-header-user {
    position: relative;
    height: 44px;
    line-height: 44px;
  }

  [for='nav-header-user-switch'] {
    cursor: pointer;
    font-size: 12px;
    color: #333;
    margin: 0 16px;
    padding: 10px 0;
    text-transform: uppercase;
    display: inline;
  }

  #nav-header-menu [for='nav-header-user-switch']:hover i::before,
  [for='nav-header-user-switch']:hover {
    color: #000;
  }

  .ie8 [for='nav-header-user-switch'],
  .lt-ie9 [for='nav-header-user-switch'] {
    margin: 0;
  }

  .ie8 #nav-header-menu [for='nav-header-user-switch'] a,
  .lt-ie9 #nav-header-menu [for='nav-header-user-switch'] a {
    font-size: 12px;
  }

  #nav-header-user-switch {
    display: none;
  }

  #nav-header-user-switch:checked + .nav-header-user-layer {
    display: block;
    text-align-last: left;
  }

  .nav-header-user-layer {
    background-color: #fff;
    border: 1px solid #dedede;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgb(99 99 99 / 20%);
    right: 0;
    position: absolute;
    top: 100%;
    z-index: 3;
    width: 150px;
    display: none;
  }

  .nav-header-user-layer::after,
  .nav-header-user-layer::before {
    border: outset transparent;
    border-bottom-style: solid;
    bottom: 100%;
    content: '';
    display: block;
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0;
  }

  .nav-header-user-layer::before {
    border-bottom-color: #dedede;
    border-width: 10px;
    right: 4px;
  }

  .nav-header-user-layer::after {
    border-bottom-color: #fff;
    border-width: 9px;
    right: 5px;
  }

  #nav-header-menu .nav-header-user-layer a {
    color: #000;
    display: block;
    line-height: 25px;
    margin: 3px 0;
    padding: 5px 15px;
    text-decoration: none;
    border-left: none;
  }

  #nav-header-menu .nav-header-user-layer a:last-child {
    display: block;
  }

  #nav-header-menu .nav-header-user-layer a:hover {
    color: #000;
    background-color: #fefbd6;
  }

  .nav-logo {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/230734061556-Mercado-Libre--2-.png');
    height: 56px;
    top: 0;
    width: 159px;
  }

  [data-country='BR'] .nav-logo,
  [data-country='PT'] .nav-logo,
  .nav-logo[href$='pt_br'] {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/191761039894-Mercado-Libre.png');
    background-size: contain;
  }
}
@media (width >= 768px) and (min-device-pixel-ratio: 2),
  (width >= 768px) and (resolution >= 192dpi),
  (width >= 768px) and (resolution >= 2dppx) {
  .nav-logo {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/230734061556-Mercado-Libre--2-.png');
    background-size: contain;
  }

  [data-country='BR'] .nav-logo,
  [data-country='PT'] .nav-logo,
  .nav-logo[href$='pt_br'] {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/191761039894-Mercado-Libre.png');
    background-size: contain;
  }
}
@media (width >= 768px) {
  .nav-search {
    padding: 7px 5px;
    overflow: auto;
    max-width: 540px;
    height: 54px;
    position: relative;
    left: -5px;
  }

  input[type='text'].nav-search-input {
    padding: 5px 60px 5px 15px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #ccc;
  }

  input[type='text'].nav-search-input:focus {
    border: 1px solid #ccc;
    padding: 5px 60px 5px 15px;
  }

  input[type='text'].navinput::input-placeholder {
    visibility: hidden;
  }

  input[type='text'].navinput:input-placeholder {
    visibility: hidden;
  }

  input[type='text'].nav-search-input::placeholder {
    visibility: hidden;
  }

  input[type='text'].nav-search-input .ch-autocomplete-loading {
    background-position: right 10px center;
  }

  button.nav-search-btn,
  button.nav-search-btn:focus {
    border-radius: 0 3px 3px 0;
    width: 46px;
    height: 40px;
    border: 1px solid #ccc;
    background-color: #fff;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fff),
      to(#f1f1f1)
    );
    background-image: linear-gradient(#fff, #f1f1f1);
    background-image: linear-gradient(#fff, #f1f1f1);
    top: 7px;
    right: 5px;
  }

  .nav-footer {
    background-color: #f7f7f7;
    border-top: 1px solid #eee;
    line-height: 1;
    font-size: 13px;
    font-weight: 400;
  }

  .nav-footer .nav-bounds {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .nav-footer :focus {
    outline: 0;
  }

  .nav-footer .nav-footer-change-device,
  .nav-footer .nav-footer-change-device:active,
  .nav-footer .nav-footer-change-device:link,
  .nav-footer .nav-footer-change-device:visited {
    float: none;
    border-left: 1px solid #ddd;
    font-size: 13px;
    padding: 0 10px;
  }

  .nav-footer-copyright {
    margin: 0 10px 5px 0;
    color: #999;
    vertical-align: initial;
    width: auto;
    line-height: initial;
    font-size: 12px;
  }

  .nav-footer-navigation {
    display: inline-block;
    font-size: 0;
  }

  .nav-footer-navigation a {
    border-right: 1px solid #ddd;
    font-size: 13px;
    padding: 0 10px;
  }

  .nav-footer-navigation a:last-of-type {
    border-right: none;
  }

  .nav-footer-navigation a:first-of-type {
    padding-left: 0;
  }

  .nav-footer-primaryinfo {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    padding-top: 0;
    font-size: 0;
  }

  .nav-footer-secondaryinfo {
    display: block;
    margin: 7.5px 0 0;
  }

  .nav-footer-downloadapp-banner {
    display: none;
  }

  .nav-footer a.nav-footer-downloadapp {
    background-color: #ffdb08;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 11px;
    color: #333;
    line-height: 28px;
    vertical-align: middle;
    display: inline-block;
  }

  .nav-icon.nav-icon-downloadapp {
    background: url('data:image/svg+xml;utf8,<svg width="13" height="20" viewBox="0 0 13 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 .8C0 .4.4 0 .8 0h11.4c.4 0 .8.4.8.8v18.4c0 .4-.4.8-.8.8H.8c-.4 0-.8-.4-.8-.8V.8zm6.5 17.5c.4 0 .8-.3.8-.8s-.4-.8-.8-.8-.8.3-.8.8.4.8.8.8zm-5-16.6h10v14h-10v-14z" fill="%23333" fill-rule="evenodd"/></svg>');
    width: 13px;
    height: 20px;
    display: inline-block;
    margin-right: 9px;
    position: relative;
    top: 5px;
  }

  .lt-ie9 .nav-icon.nav-icon-downloadapp {
    display: none;
  }

  .nav-footer-downloadapp-wrapper,
  .nav-footer-info-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .nav-footer-info-wrapper {
    width: 76.5%;
  }

  .nav-footer-downloadapp-wrapper {
    width: 23%;
    min-width: 278px;
  }
}
@media (width >= 768px) and (width <= 1024px) {
  .nav-footer-downloadapp-wrapper {
    width: 12%;
    min-width: 126px;
  }

  .nav-footer-downloadapp-wrapper a.nav-footer-downloadapp {
    line-height: 14px;
    text-align: center;
    padding: 4px 7px;
  }

  .nav-footer-downloadapp-wrapper .nav-icon.nav-icon-downloadapp {
    display: none;
  }
}
@media (width >= 768px) {
  .nav-footer-secondaryinfo {
    padding-right: 10px;
    line-height: 15px;
  }

  .nav-footer-access {
    display: block;
    position: relative;
    font-size: 13px;
    font-weight: 400;
  }

  .nav-footer-access [for='nav-footer-access-switch'] i::before {
    transform: rotate(180deg);
  }

  .nav-footer-access,
  .nav-footer-access *,
  .nav-footer-access ::after,
  .nav-footer-access ::before {
    box-sizing: border-box;
  }

  .nav-footer-access :focus {
    outline: 0;
  }

  .nav-footer-access-switch:checked
    + .nav-footer-access
    .nav-footer-access-content {
    max-height: 0;
    border-top: none;
  }

  .nav-footer-access-switch:checked
    + .nav-footer-access
    [for='nav-footer-access-switch']
    i::before {
    transform: rotate(0);
  }

  [for='nav-footer-access-switch'] {
    background-color: #f7f7f7;
    border: 1px solid #eee;
    border-bottom: none;
    color: #666;
    cursor: pointer;
    padding: 0 20px;
    height: 32px;
    line-height: 32px;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translate(-50%, 1px);
    border-radius: 5px 5px 0 0;
    z-index: 1;
  }

  [for='nav-footer-access-switch'] i {
    margin-left: 2px;
    font-size: 11px;
    top: 0;
    position: relative;
  }

  [for='nav-footer-access-switch'] i::before {
    transition: all 0.2s linear;
  }

  .ie8 [for='nav-footer-access-switch'],
  .lt-ie9 [for='nav-footer-access-switch'] {
    display: none;
  }

  .nav-footer-access-content {
    line-height: 0;
    max-height: 170px;
    overflow: hidden;
    transition: max-height 0.2s linear;
    font-size: 0;
    background-color: #f7f7f7;
    border-top: 1px solid #eee;
    margin-top: 64px;
    position: relative;
    bottom: 0;
  }

  .nav-footer-access-content a,
  .nav-footer-access-content a:active,
  .nav-footer-access-content a:link,
  .nav-footer-access-content a:visited {
    color: #999;
    text-decoration: none;
  }

  .nav-footer-access-content a:hover {
    text-decoration: underline;
  }

  .nav-footer-access-content [class^='nav-icon-']::before,
  .nav-footer-access-content [href*='twitter.com']::before,
  .nav-footer-access-content [href*='facebook.com']::before,
  .nav-footer-access-content [href*='plus.google.com']::before,
  .nav-footer-access-content [href*='youtube.com']::before,
  .nav-footer-access-content [href*='instagram.com']::before {
    font-size: 18px;
    margin-right: 7px;
    vertical-align: top;
  }

  .nav-footer-access-content .nav-icon-twitter::before {
    font-size: 20px;
    margin-right: 5px;
  }

  .nav-footer-access-content [href*='twitter.com'],
  .nav-footer-access-content [href*='facebook.com'],
  .nav-footer-access-content [href*='plus.google.com'],
  .nav-footer-access-content [href*='youtube.com'],
  .nav-footer-access-content [href*='instagram.com'] {
    font-style: normal;
  }

  .nav-footer-access-content [href*='twitter.com']::before,
  .nav-footer-access-content [href*='facebook.com']::before,
  .nav-footer-access-content [href*='plus.google.com']::before,
  .nav-footer-access-content [href*='youtube.com']::before,
  .nav-footer-access-content [href*='instagram.com']::before {
    display: inline-block;
    font-variant: normal;
    margin: 0 7px 0 0;
    speak: none;
    text-align: center;
    width: 1em;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: navigation;
    font-size: 18px;
    vertical-align: top;
  }

  .nav-footer-access-content [href*='twitter.com']::before {
    content: '\EA19';
    font-size: 20px;
    margin-right: 5px;
  }

  .nav-footer-access-content [href*='facebook.com']::before {
    content: '\EA09';
  }

  .nav-footer-access-content [href*='plus.google.com']::before {
    content: '\EA0A';
  }

  .nav-footer-access-content [href*='youtube.com']::before {
    content: '\EA1C';
  }

  .nav-footer-access-content [href*='instagram.com']::before {
    content: '\EA0D';
  }

  .nav-footer-access-col {
    width: 20%;
    font-size: 13px;
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
    margin: 15px 0;
  }

  .nav-footer-access-col ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .nav-footer-access-title {
    color: #666;
    font-weight: 700;
    margin: 0 0 5px;
    font-size: 13px;
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
  }

  #nav-header-menu a {
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 14px;
    line-height: 53px;
  }

  #nav-header-menu a [class*=' nav-icon-']::before,
  #nav-header-menu a [class^='nav-icon-']::before {
    display: inline-block;
  }

  #nav-header-menu a.option-cart,
  #nav-header-menu a.option-sell {
    border-left: 1px solid #dccf50;
  }

  #nav-header-menu a.bookmarks-widget,
  #nav-header-menu a.notifications-widget,
  #nav-header-menu a.option-help {
    padding-left: 7px;
    padding-right: 7px;
  }

  #nav-header-menu a.option-sell {
    margin-left: 9px;
  }

  #nav-header-menu a.option-login {
    margin-right: 9px;
    border-right: 1px solid #dccf50;
  }

  #nav-header-menu:first-child {
    border-left: none;
  }

  #nav-header-menu [for='nav-header-user-switch'] {
    margin-left: 7px;
    margin-right: 7px;
  }

  #nav-header-menu .option-cart {
    display: inline;
  }

  #nav-header-menu i::before {
    text-shadow: none;
  }

  #nav-header-menu i.nav-icon-help::before {
    font-size: 16px;
  }

  .nav-bounds.nav-bounds-with-cart #nav-header-menu {
    margin-right: 45px;
  }

  .nav-bounds.nav-bounds-with-cart .nav-cart {
    border-left: 1px solid #dccf50;
    top: 18px;
    height: 18px;
  }

  .nav-bounds.nav-bounds-with-cart .nav-cart .nav-icon-cart {
    margin-left: 0;
    position: relative;
    top: -3px;
  }

  .nav-bounds.nav-bounds-with-cart .nav-cart ::before {
    line-height: 27px;
    font-size: 16px;
    color: #333;
  }

  .nav-bounds.nav-bounds-with-cart .nav-cart .nav-icon-cart-quantity {
    display: inline-block;
    top: -3px;
    right: 8px;
    font-size: 11px;
    line-height: 15px;
    color: #333;
  }

  .nav-bounds.nav-bounds-with-cart .nav-cart:hover .nav-icon-cart-quantity,
  .nav-bounds.nav-bounds-with-cart .nav-cart:hover ::before {
    color: #000;
  }

  .nav-footer-primaryinfo a,
  .nav-footer-primaryinfo a:active,
  .nav-footer-primaryinfo a:link,
  .nav-footer-primaryinfo a:visited {
    color: #0637b3;
    text-decoration: none;
  }

  .nav-header {
    background-color: #fff059;
    border-bottom: 1px solid #d9d9d9;
  }

  .nav-header-has-search-active .nav-search {
    left: -5px;
    right: initial;
  }

  .nav-header-has-search-active .nav-search .nav-search-close-btn,
  .nav-header-has-search-active .nav-search::before {
    display: none;
  }

  .nav-header-has-search-active button.nav-search-btn,
  .nav-header-has-search-active button.nav-search-btn:focus {
    display: inline-block;
  }

  button.nav-search-btn,
  button.nav-search-btn:focus {
    left: auto;
  }

  .nav-search-btn .nav-icon-search {
    font-size: 18px;
  }

  .nav-search-btn .nav-icon-search::before {
    content: '\EA16';
    vertical-align: top;
  }

  .nav-search-clear-btn {
    display: none;
  }

  #nav-header-menu .nav-icon-notifications::before {
    content: '\EA25';
  }

  #nav-header-menu .nav-icon-bookmarks::before {
    content: '\EA1E';
  }

  #nav-header-menu .nav-icon-help::before {
    content: '\EA24';
  }

  #nav-header-menu .nav-icon-user::before {
    content: '\EA26';
  }

  .nav-cart.nav-cart-full .nav-icon-cart::before {
    content: '\EA22';
  }

  .nav-cart.nav-cart-empty .nav-icon-cart::before {
    content: '\EA1F';
  }

  input[type='text'].navinput::input-placeholder {
    visibility: visible;
  }

  input[type='text'].navinput:input-placeholder {
    visibility: visible;
  }

  input[type='text'].nav-search-input::placeholder {
    visibility: visible;
  }
}
@media (width >= 768px) and (width <= 1200px) {
  .nav-bounds-large {
    padding: 0 32px;
  }

  .nav-bounds-large .nav-footer-info-wrapper {
    padding-right: 10px;
    padding-left: 0;
  }

  .nav-header .nav-bounds-large {
    padding: 0 32px 0 242px;
  }

  .nav-header .nav-bounds-large .nav-logo {
    left: 32px;
  }

  .nav-header .nav-bounds-large .nav-cart {
    margin-right: 32px;
  }
}

@media (width >= 1024px) {
  #nav-header-menu > div:first-child {
    display: inline-block;
  }

  #nav-header-menu .nav-header-user {
    height: 100%;
    position: static;
  }

  #nav-header-menu [for='nav-header-user-switch'] {
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 13px;
    line-height: initial;
  }

  #nav-header-menu .nav-icon-user::before {
    vertical-align: middle;
    font-size: 16px;
    background-color: #fff;
    z-index: 1;
    position: relative;
  }

  .nav-bounds.nav-bounds-with-cart #nav-header-menu {
    min-width: 295px;
    padding: 0;
    margin-right: 45px;
  }

  .nav-header .nav-bounds {
    max-width: 1220px;
  }
}

@media (width <= 1023px) {
  .nav-header .nav-bounds .nav-search {
    position: relative;
    padding-left: 20px;
    border-bottom: 1px solid #ededed;
    height: 50px;
    width: 100%;
    left: 0;
  }

  .nav-header .nav-logo {
    background-size: 102px 47px;
    height: 40px;
    top: 6px;
  }

  .nav-header .nav-bounds .nav-search {
    display: none;
  }

  .nav-header-user-layer {
    border-bottom: 1px solid #ededed;
  }

  #nav-header-menu {
    padding: 0;
  }

  #nav-header-menu a {
    padding: 0;
    padding-left: 26px;
    height: auto;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 22px;

    &:first-child {
      margin-top: 20px;
    }
  }

  #nav-header-menu .login-btn {
    color: #3483fa;
    border-bottom: 1px solid #ededed;
    padding: 20px 26px;
    font-size: 16px;
    line-height: 16px;
    height: auto;
  }

  #nav-header-menu .option-logout {
    color: #3483fa;
    font-size: 18px;
  }
}

@media (width <= 768px) {
  .nav-header .nav-logo {
    background-size: 120px 50px;
    top: 0;
    width: 110px;
  }
}

.nav-header::before {
  height: auto;
}
