.supportnotcompliant__app {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  width: 423px;
  height: 511px;
  margin-bottom: 0 !important;
  margin-left: -243px;
  margin-top: -250px;
  justify-content: center;
}

.supportnotcompliant__app > svg {
  margin-top: 10%;
  margin-left: 30%;
  margin-bottom: 5%;
}

.supportnotcompliant_body__app {
  text-align: center;
  margin-block-end: 50px;
}

.supportnotcompliant_body__app > h2 {
  font-size: 20px !important;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 15%;
  padding-top: 0 !important;
}

.supportnotcompliant_body__app > p {
  font-weight: 100;
  line-height: normal !important;
  margin: 5%;
  margin-left: 102px;
  margin-right: 47px;
}

.supportnotcompliant__app > .start_button__app,
.whycompletedata_link__app {
  left: 48% !important;
  position: fixed;
  transform: translate(-50%, -50%) !important;
  width: 55% !important;
}

.whycompletedata_link__app {
  color: #3483fa;
  cursor: pointer;
  margin-top: 45px !important;
  text-align: center;
}
