.supportnotcompliant__app {
  height: 460px;
  justify-content: center;
  left: 50%;
  margin-bottom: 0 !important;
  margin-left: -243px;
  margin-top: -240px;
  position: absolute;
  top: 50%;
  transform: translate(0%, 0%);
  width: 480px;
}

.supportnotcompliant__app > svg {
  margin-top: 10%;
  margin-left: 25%;
  margin-bottom: 5%;
}

.supportnotcompliant_body__app {
  text-align: center;
  margin-block-end: 50px;
}

.supportnotcompliant_body__app > h2 {
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 0;
  padding-top: 0 !important;
}

.supportnotcompliant_body__app > p {
  font-size: 12px;
  font-weight: 100;
  line-height: normal !important;
  margin-left: 75px !important;
  margin-right: 47px;
  max-width: 333px;
}

.supportnotcompliant__app > .start_button__app,
.whycompletedata_link__app {
  left: 50% !important;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 80% !important;
}

.whycompletedata_link__app {
  color: #3483fa;
  cursor: pointer;
  margin-top: 40px;
  text-align: center;
}

.nav-header-user {
  margin-top: 10px !important;
}
