.content {
  background: $bg-list-gray;
  overflow: hidden;
}

.header-section__two-column {
  display: flex;
  justify-content: space-between;
  margin: 56px 0 0;
  overflow: hidden;
  background: linear-gradient(0.08deg, #547396 0.07%, #141e30 105.73%);
  height: 250px;
  font-weight: 600;
  color: $white;

  .header-img__two-column {
    display: flex;
    align-items: end;
    height: 283px;
    width: 459.4px;

    img {
      height: 336px;
      background: no-repeat;
    }
  }

  .two-column__bug-bounty {
    padding: 80px;
    padding-left: 0;
  }

  .header-texts__two-column {
    padding-left: 80px;
    display: flex;
    flex-direction: column;

    p {
      font-weight: 600;
      font-size: $font-size-xxxxl;
      line-height: 40px;
      margin: 0 0 12px;
    }

    span {
      font-weight: 400;
      font-size: $font-size-s;
      line-height: 18px;
    }
  }
}

.two-column-breadcrumbs-container {
  margin: 0 0 46px;

  .andes-breadcrumb {
    display: flex;
    padding: 0;
    margin: 0;

    .andes-breadcrumb__label {
      color: $white;
    }

    .andes-breadcrumb__item {
      display: flex;
      flex-direction: row;

      :visited {
        color: $blue-100;
      }
    }

    .andes-breadcrumb__chevron {
      display: flex;
      align-items: center;

      svg {
        path {
          stroke: $white;
        }
      }
    }
  }
}

.breadcrumbs__two-column {
  padding: 32px 0 0;
}

.mesh-col-l-12 {
  display: flex;
  padding: 47px 47px 82px 80px;
}

.mesh-col-l-3,
.mesh-col-l-7 {
  background: $white;
  border-radius: 6px;
}

.mesh-col-l-3,
.mesh-col-l-7 .mesh-row__content,
.two-column__btn-content-fixed {
  box-sizing: border-box;
}

.mesh-col-l-3 {
  width: 19.3%;
  height: fit-content;
  margin: 0 16px 0 0;
  padding: 40px 32px;

  .mesh-col-l-3__title {
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-xxxl;
    line-height: 30px;
    padding: 0 0 30px;
  }

  .nav a,
  .nav label {
    padding: 10px 0;
  }

  .nav__list {
    margin: 0;
    padding: 0;
  }

  .group-list {
    padding: 10px 5px 0;
  }
}

.content,
.tab {
  background: $white;
}

.mesh-col-l-12 {
  background: $bg-list-gray;
}

.mesh-col-l-7 {
  flex: 1;
  padding: 32px;
  position: relative;
  overflow-x: auto;
}

.andes-table {
  font-size: $font-size-16;
  display: block;
  overflow: scroll hidden;
}

.two-column__btn-content-wrap {
  padding: 0;
  width: 32px;
}

.two-column__btn-content-wrap--open {
  width: 231px;
}

.inner-content {
  display: inherit;
}

.lastUpdateSpan {
  margin: 0 0 16px;
}

.two-column__btn-content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
}

.two-column__btn-content-fixed,
.two-column__btn-content-closed-fixed {
  position: fixed;
  top: 0;
  z-index: 999;
  margin: 56px 0 0;
}

.two-column__btn-content-closed,
.two-column__btn-content-closed-fixed {
  display: flex;
  align-items: center;
  width: 32px !important;
  height: 48px;
  background: $blue-100;
  border-radius: 0 6px 6px 0;
  border: none;
  cursor: pointer;

  svg {
    path {
      fill: $white;
      transform: rotate(-180deg) translateX(-33px) translateY(-33px);
    }
  }
}

.two-column__btn-content-open {
  padding: 0;
  border: none;
  cursor: pointer;

  svg {
    display: flex;
    align-items: center;
    width: 32px;
    height: 48px;
    background: #1f4e96;
    border-radius: 6px 0 0 6px;

    path {
      fill: $white;
    }
  }
}

.nav__list li[data-title='API Docs'] > label {
  display: none !important;
}

.chevron-direction {
  display: flex;
  flex-direction: row;
}

.btn-list-container {
  display: flex;
  max-height: 400px;
  overflow-y: auto;
  height: auto;
  width: 231px;
  background: $gray-885;
  border-radius: 6px 6px 6px 0;
  padding: 18px 30px 18px 33px;

  &::-webkit-behavior {
    background: $gray-1000;
  }

  .andes-list {
    background: $gray-885;
    height: 100%;
    width: 100%;

    .andes-list__item-first-column {
      margin: 0 8px;
    }

    .andes-list__item-text span {
      font-weight: 400;
      font-size: $font-size-xxsmall;
      line-height: 15px;
    }

    .andes-list__item {
      padding: 0;

      &:hover {
        border-left: 2px solid $blue-100;
      }
    }
  }
}

.two-column__btn-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 198px;
  height: 48px;
  padding: 17px 16px 15px;
  font-family: $font-family-primary;
  color: $white;
  font-weight: 600;
  font-size: $font-size-base;
  line-height: 16px;
  background: $blue-100;
  border-radius: 0 6px 6px 0;
  border: none;
}

.doc-cross__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 24px;
  padding: 25px;
  border-left: 4px solid $blue-100;
  background: rgb(0 0 0 / 4%);
  border-radius: 6px;
}

.doc-cross__text {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0 10px 0 0;
    padding: 0 0 0 32px;
    font-weight: 400;
    font-size: $font-size-s;
    line-height: 18px;
  }
}

.doc-cross__icon {
  position: absolute;
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/228735979400--badge--1-.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
}

.doc-cross__buIcons {
  display: flex;
  flex-direction: row;
}

.doc-cross__banner-ML,
.doc-cross__banner-MS,
.doc-cross__banner-MP,
.doc-cross__banner-ME {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 14px 0 0;
  width: 53.47px;
  height: 29px;
}

.doc-cross__banner-ML {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/214206523880-Frame-1123-3.png');
}

.doc-cross__banner-MP {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/214206523880-Frame-1123-2.png');
}

.doc-cross__banner-ME {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/214206523880-Frame-1123-1.png');
}

.doc-cross__banner-MS {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/214206523880-Frame-1123.png');
}

.inner-content {
  h2:first-of-type {
    font-weight: 600;
    font-size: $font-size-xxxl;
    line-height: 30px;
    margin: 14px 0 24px;
    padding: 0;
  }
}
