@import '../../styles/navigation';
@import '../../styles/variables';
@import '../../styles/mesh';
@import '../../components/HeaderView/header';
@import '../../components/CommunityView/community';
@import '../../components/FooterView/footer';
@import '../../components/TwoColumn/index';
@import '~@andes/form/index';
@import '~@andes/textfield/index';
@import '~@andes/card/index';
@import '~@andes/tag/index';
@import '~@andes/checkbox/index';
@import '~@andes/message/index';
@import '~@andes/badge/index';
@import '~@andes/button/index';
@import '~@andes/floating-menu/index';
@import '~@andes/list/index';
@import '~@andes/table/index';
@import '~@andes/carousel-free/index';
@import '../../components/GoToTop/gototop';
@import '../../components/Kyc/NotCompliant/styles';
@import '../../components/Kyc/Backoffice/styles';
@import '../../components/Kyc/Congrats/styles';

@import 'content__small';

@media (width >= 678px) {
  @import 'content__large';
}

@import './components/styled__utilities';
@import './prism';
@import '../../components/FormSubscription/subscription';
