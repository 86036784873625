.supportcongrats__header_background {
  background: #f73;
  height: 248px;
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0 !important;
}

.supportnotcompliant__app-congrats {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 65%;
  left: 50%;
  background: #fff;
  width: 423px;
  height: 175px;
  margin-bottom: 0 !important;
  margin-left: -210px;
  margin-top: -195px;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);
  border-radius: 6px;
  z-index: index 1;
}

.start_button__app {
  left: 50% !important;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 50% !important;
}

.start_button__app-congrats {
  margin-bottom: 40px;
}

.support-container {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  margin-top: -80px;
  position: relative;
}

.supportcongrats__app {
  height: 175px;
  justify-content: center;
  left: 51%;
  margin-bottom: 0 !important;
  margin-left: -243px;
  margin-top: -268px;
  position: absolute;
  top: 40%;
  width: 480px;
}

.supportcongrats__app > h2 {
  color: #ffff;
  font-weight: normal;
  margin: 0;
  text-align: center;
}

.supportcongrats__app > svg {
  margin-bottom: 3%;
  margin-left: 30px;
}

.supportnotcompliant_body__app-congrats {
  text-align: center;
  padding-top: 30px;
  width: 298px;
  height: 40px;

  p {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
  }
}

.supportnotcompliant__app-congrats.mobile {
  background: none;
  box-shadow: none;
}
