@import '../../styles/variables';
@import '../content/content';
@import '../../components/CommunityView/community';
@import '../../components/FooterView/footer';
@import './components/HeaderView/styles';

.inner-content {
  min-height: calc(100vh - 116px);
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .solution {
    width: 100%;
    background-color: $gray-885;
  }
}

.andes-card__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.andes-card {
  border-radius: 4px;
  padding: 0;
  box-shadow: 0 0 1px 1px $black-007-opacity, 0 1px 2px 0 $black-025-opacity;
  max-width: 940px;
}

.solution-card {
  width: 100%;
  max-width: 940px;
  margin: 0 auto 90px;

  .solution-card__container {
    text-align: left;
    border-bottom: 1px solid $gray-700;
    padding: 5px 24px 10px;
  }

  .solution-card__container:last-child {
    border-bottom: none;
  }

  .solution-card__info {
    max-width: 940px;
    margin: 19px 24px 5px;
  }

  .solution-card__image {
    margin-right: 24px;
    display: inline-block;

    img {
      width: 160px;
      height: 160px;
    }
  }

  .solution-card__title {
    text-align: left;
  }

  .solution-card__details {
    display: inline-block;
    max-width: 644px;
  }

  .solution-card__contact {
    p {
      display: inline-block;
      margin-top: 10px;
    }
  }

  .solution-card__mail {
    margin: 0 24px;
  }
}

.partner-solution-icon {
  display: flex;

  img {
    padding-right: 8px;
  }
}

// Styles for Landing Hub
// CAROUSEL
.slider {
  width: 1200px;
  height: 424px;
}

.slides {
  height: 424px;
}

.slide > div {
  width: 1200px;
  height: 424px;
  flex-direction: row;
}

.navigation-manual {
  width: 1200px;
  margin-top: -40px;
}

.landinghub-home-carousel__texts-without-cta-container {
  margin-top: 64px;
}

.landinghub-home-carousel__texts-container > .andes-button {
  margin-bottom: 6rem;
  position: absolute;
  bottom: 0;
}

.landinghub-home-carousel__texts-container {
  margin-top: 0;
}

.landinghub-home-carousel__title {
  font-size: 37px;
  line-height: 48px;
  margin-right: 40px;
  max-width: 500px;
}

.landinghub-home-carousel__subtitle {
  font-size: 20px;
  line-height: 24px;
  margin: 8px 40px 40px 0;
  max-width: 520px;
}

.landinghub-home-carousel__image {
  width: 533px;
  height: 360px;
  margin: 0 0 -65px;
}

.landinghub-home-carousel__logo {
  width: 207px;
  height: 80px;
  bottom: 10%;
  right: 2%;
}

// Automatic transition for carousel banners
.navigation-auto {
  width: 1200px;
  margin-top: 384px;
}

// EXPERTS DESCRIPTION
.landinghub-home-experts-description__container > h2 {
  font-size: 32px;
}

.landinghub-home-experts-description__container > p {
  font-size: 18px;
}

.landinghub-home-experts-description__cards-container {
  display: flex;
  padding: 0 40px;
}

.landinghub-home-experts-description__cards-container > .partner-card {
  width: 225px;
}

// EVENTS
.landinghub-home-events__container {
  padding: 6.25rem 4.75rem;
  display: flex;
  justify-content: center;
}

.landinghub-home-events-maxwidth__container {
  max-width: 1200px;
}

.landinghub-home-events-maxwidth__container > h2 {
  font-size: 32px;
  line-height: 40px;
}

.landinghub-home-events__cards-container {
  grid-template-columns: 1fr 1fr 1fr;
}

.landinghub-home-events__card {
  margin-bottom: 0;
}

// EXPERTS FILTER
.landinghub-home-experts-filter__container {
  background-color: $white;
  padding: 6.25rem 4.75rem;
  display: flex;
  justify-content: center;
}

.landinghub-home-experts-filter-maxwidth__container {
  max-width: 1200px;
}

.landinghub-home-experts-filter__subtitle {
  padding: 0 17.5rem;
}

.landinghub-home-experts-filter__numbers > h1 {
  font-size: 43px;
  line-height: 29px;
}

.landinghub-home-experts-filter__numbers > h3 {
  font-size: 26px;
  line-height: 29px;
}

.landinghub-home_solutions__container {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 500px;
}

.landinghub-home_filterSF__solution-logo {
  height: 120px;
  max-width: 120px;
}

.landinghub-home_filterSF__button-see-all {
  position: absolute;
  right: 4%;
  top: 5%;

  @media (width <= 1300px) {
    right: 7%;
  }
}

.solution__list-stage {
  min-width: 800px;
}

.landinghub-home_logos__container {
  justify-content: start;
}

// VIDEO
.landinghub-home-video__container {
  background-color: rgb(71 154 209 / 10%);
  width: 100%;
  height: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5.5rem;
}

.landinghub-home-video__container > h2 {
  font-size: 28px;
  line-height: 35px;
}

.landinghub-home-video__container > p {
  font-size: 18px;
  line-height: 22px;
}

.landinghub-home-video__iframe {
  position: relative;
  width: 50%;
  padding-bottom: 30%;
}

.landinghub-home-video__iframe > iframe {
}

.landinghub-home-video__container > a {
  margin-top: 2.875rem;
}

// PROGRAMS
.landinghub-home-programs__container {
  background-color: #e5e5e5;
  padding: 56px 70px;
  display: flex;
  justify-content: center;
}

.landinghub-home-programs-maxwidth__container {
  max-width: 1200px;
  display: flex;
}

.landinghub-home-programs__item {
  padding: 0 40px;
}

.landinghub-home-programs__item > h3 {
  font-size: 24px;
  line-height: 30px;
  padding: 0 16px;
  height: 60px;
}

.landinghub-home-programs__item > p {
  font-size: 18px;
  line-height: 22px;
  height: 88px;
  margin-bottom: 24px;
}

.landinghub-home-programs__item > .andes-button {
  margin: 0 0 16px;
}

// Styles for Landing Hub - Events Page
.landinghub-event__grey-background {
  background-color: #ebebeb;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .partner-breadcrumbs-container {
    padding-left: 65px;
  }
}

.landinghub-event__container {
  width: 1070px;
}

.partner-breadcrumbs-container {
  margin-bottom: 30px;
  opacity: 1;
  display: block;
}

.landinghub-event__mainInfo__container {
  border-right: 1px solid $tap-highlight-color;
  max-width: 720px;
}

.landinghub-event__topInfo__container {
  max-width: 1200px;
}

.landinghub-event-video__iframe {
  padding-bottom: 0%;
  height: auto;
  max-width: 719px;
}

.landinghub-event-video__iframe > iframe,
.landinghub-event-image {
  padding: 0;
}

.landinghub-event-image > img {
  width: 100%;
  height: auto;
  max-width: 719px;
}

.landinghub-event__mainInfo__all-texts {
  margin-top: -185px;
  padding-top: 120px;
}

.landinghub-event__mainInfo__all-texts > h3 {
  font-size: 24px;
  line-height: 30px;
}

.landinghub-event__video-sideInfo__alignment {
  display: flex;
  height: 100%;
}

.landinghub-event__sideInfo-all-texts {
  height: 100%;
}

.landinghub-event__sideInfo-all-texts,
.landinghub-event__sideInfo-logos {
  justify-content: flex-end;
}

.landinghub-event__sideInfo-all-texts > h3 {
  font-size: 24px;
  line-height: 30px;
}

.landinghub-event__mainInfo__all-texts > p,
.landinghub-event__mainInfo__numbered-list > li,
.landinghub-event__mainInfo__bullets-list > li,
.landinghub-event__sideInfo-logos-title,
.landinghub-event__sideInfo-all-texts > p {
  font-size: 18px;
  line-height: 22px;
}

.landinghub-event__sideInfo-all-texts > p {
  max-width: 470px;
}

.landinghub-event__sideInfo-text {
  font-size: 14px;
  line-height: 18px;
}

/* Delipop Dulcería */

.delipop__container {
  width: 785px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .delipop__container__section {
    padding: 16px 0;

    .delipo__container__section-title {
      margin: 0 0 30px;
      font-size: 40px;
      color: rgb(0 0 0 / 80%);
      line-height: 1.4;
    }

    .delipop__container__section-list {
      .delipop__container__section-itens {
        margin-left: 63px;
        padding: 0;

        .delipop__container__section-item {
          font-size: 20px;
          line-height: 1.4;
          color: rgb(0 0 0 / 80%);
        }
      }
    }
  }

  .delipop__container__section__brigada {
    .delipop__container__section__brigada-title {
      padding: 0;
      margin: 20px 0;
      font-size: 28px;
      line-height: 35px;
    }

    .delipop__container__section__brigada-paragraph {
      line-height: 1.4;
      font-size: 18px;
      margin-bottom: 20px;
    }

    .delipop__container__section__brigada-video {
      margin: 20px 0;

      .delipop__container__section__brigada-player {
        width: 100%;
        height: 393px;
      }
    }

    .delipop__container__section__brigada-subscribe {
      margin-bottom: 20px;

      .delipop__container__section__brigada-subscribe-paragraph {
        font-size: 17px;
      }
    }

    .delipop__container__section__brigada-card {
      display: flex;
      flex-direction: column;
      margin-bottom: 60px;
      justify-content: center;
      margin-top: 30px;
      min-height: 288px;
      width: 785px;
      align-items: center;
      border-radius: 6px;
      box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);

      .delipop__container__section__brigada-buttons-title {
        font-size: 24px;
        text-align: center;
        font-weight: 600;
      }

      .delipop__container__section__brigada-buttons {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .delipop__container__section__brigada-button {
          max-width: 530px;
          width: 100%;
          background-color: #3483fa;
          border: none;
          border-radius: 6px;
          margin-top: 15px;
          min-height: 48px;
          padding: 16px 24px;

          .delipop__container__section__brigada-button-title {
            color: #fff;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
          }
        }
      }
    }
  }
}
