.details__title {
  font-size: 24px;
  margin: 0 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: rgb(0 0 0 / 80%);
}

.details__links-content {
  padding: 0 16px;
  margin: 18px 15px 30px;
}

.link-index {
  &.link-index {
    display: block;
    margin-bottom: 20px;
    color: #3483fa;
    text-decoration: none;
    font-size: 16px;
  }
}

.details__message {
  margin: 0 15px 32px;
}

.details__message-text {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: rgb(0 0 0 / 80%);
}

.content__image {
  width: 100%;
  max-height: 473px;
}

.content__description-image {
  font-size: 16px;
  line-height: normal;
  color: rgb(0 0 0 / 45%);
  text-align: center;
  margin-bottom: 32px;
  margin-top: 0;
  padding: 6px 0;
  border-bottom: 1px solid rgb(0 0 0 / 12%);
}

.details__simple-text {
  margin: 20px 15px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.76;
  letter-spacing: normal;
  color: rgb(0 0 0 / 80%);
}

.details__subtitle {
  margin-left: 15px;
  margin-top: 15px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(0 0 0 / 45%);
}

.andes-table__column {
  white-space: pre-line;
}

.details__code-terminal {
  margin: 16px 15px 30px;
  align-content: center;
}

.details__code-terminal-json {
  max-height: none;
  margin: 16px 15px 26px;
}

.andes-message {
  margin: 16px 15px;
}

.details__bullets-list {
  margin: 15px 0;
  padding-left: 6px;
}

.details__bullets-text {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/mercadoenvios/371714520085-bulletPoint.png');
  background-position: 2px 10px;
  background-repeat: no-repeat;
  font-size: 15px;
  margin: 8px 15px;
  padding-left: 14px;
  display: block;
}

.details__bullets-sub-text {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/346576221386-item-list-copy-2.png');
  background-position: 2px 10px;
  background-repeat: no-repeat;
  font-size: 15px;
  margin: 8px 0;
  padding-left: 14px;
  display: block;
}

ol {
  list-style: none;
  counter-reset: my-awesome-counter;
}

.details__bullets-number {
  counter-increment: my-awesome-counter;
  font-size: 16px;
  margin: 8px 15px;
  line-height: 22px;
}

.details__bullets-number::before {
  content: counter(my-awesome-counter);
  margin-right: 2%;
  font-size: 13px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: #3483fa;
  text-align: center;
  color: #fff;
  display: inline-block;
}

.details__textual-quote {
  margin: 10px 15px 32px;
}

.content__separator {
  width: 6px;
  height: 51px;
  background-color: #ffe600;
  display: inline-block;
  margin-top: 4px;
}

.details__text-quote {
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.76;
  letter-spacing: normal;
  color: rgb(0 0 0 / 80%);
  font-style: italic;
  vertical-align: top;
  margin: 1em 0 1em 12px;
  width: 89%;
}

.details__footer {
  padding: 22px 0;
  border-top: 1px solid rgb(0 0 0 / 12%);
}

.image-not-all {
  margin-bottom: 28px;
  width: 100%;
}

.light-blue {
  &.light-blue {
    color: #3483fa;
  }
}

.code-highlight {
  background-color: #efefef;
  border-radius: 4px;
  padding: 4px 7px;
}

.icon__feedback-success,
.icon__feedback-failure {
  width: 20px;
  height: 24px;
  display: block;
}

.icon__feedback-success {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/323775998403-feedback-success.png');
}

.icon__feedback-failure {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/323775504893-feedback-failure.png');
}

@media (width >= 678px) {
  .details__title {
    margin: 0;
  }

  h2,
  .index {
    padding-top: 65px;
    margin-top: -65px;
  }

  .details__links-content {
    margin: 18px 0 50px;
  }

  .details__message {
    margin: 0;
    margin-bottom: 32px;
    padding: 20px 32px;
    background-color: #f5f5f5;
  }

  .details__simple-text {
    margin: 20px 0;
  }

  .details__subtitle {
    margin-left: 0;
    margin-top: 0;
  }

  table.andes-table {
    margin: 10px auto;
    width: auto;
  }

  .details__code-terminal {
    margin: 16px 95px 30px;
  }

  .details__code-terminal-json {
    margin: 16px 0 30px;
  }

  .andes-message {
    margin: 16px 0;
  }

  .details__bullets-text {
    margin: 8px 0;
  }

  .details__bullets-number {
    margin: 8px 0;
  }

  .details__bullets-number::before {
    margin-right: 4px;
    font-size: 13px;
  }

  .details__textual-quote {
    margin: 10px 0 32px;
  }

  .content__separator {
    width: 6px;
    height: 51px;
    background-color: #ffe600;
    display: inline-block;
    margin-top: 0;
  }

  .details__text-quote {
    margin-top: 12px;
    margin-left: 19px;
  }

  .image-not-all {
    max-width: 916px;
    margin: 0 13px 28px 17px;
  }
}
