@import 'subscription__small';

@media (width >= 768px) {
  @import 'subscription__large';
}

.andes-widther > label > div.andes-form-control__border {
  display: none;
}

.form-subscription__oneline-wrapper > label > .andes-form-control__border {
  display: none;
}

.andes-form-control__control,
.form-subscription__btn-group {
  margin-bottom: 10px;
  text-align: center;
}

.form-subscription {
  border-top: 1px solid #eee;
  background-color: $gray-885;
}

.form-subscription__text {
  display: flex;
  flex-direction: column;
}

.form-subscription__title {
  color: $black;
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 0.9px;
  margin: 0;
  text-align: center;
}

.form-subscription__paragraph {
  font-size: 19px;
  margin: 8px 0;
}

.form-subscription__container {
  margin: 0 0 5px 16px;
  max-width: 480px;
  flex: 1 1;
}

.form-subscription-notlogged__container {
  font-size: 13px;
  padding: 10px;
  width: 100%;
}

.form-subscription_newsletter {
  padding-top: 0;
  color: $black;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.form-subscription__email {
  width: 64px;
  height: 64px;
}

.form-subscription__row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-subscription__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-subscription__img {
  width: 199px;
  height: 88px;
  align-self: center;
}

.st0 {
  fill: #fee600;
}

.st1 {
  fill: #fffffd;
}

.st2 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
}

.st3 {
  fill: #fff159;
}

.form-subscription__btn-group {
  .andes-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    width: 145px;
  }
}

@media (width >= 1023px) {
  .form-subscription__oneline-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .andes-form-control--readonly .andes-form-control__field {
      padding-left: 16px;
      font-size: 12px;
      margin: 0;
      color: rgb(0 0 0 / 55%);
    }

    .andes-widther {
      width: 100%;

      p {
        margin-top: 0;
      }
    }

    .andes-form-control__border {
      background-color: rgb(0 0 0 / 0%);
    }

    .andes-form-control {
      flex: 1;
      margin-right: 16px;
      width: 326px;
      padding-top: 0;
    }

    .andes-form-control__bottom {
      span {
        color: $black;
      }
    }

    .andes-form-control--textfield .andes-form-control---validated-icon {
      position: absolute;
    }
  }

  .form-subscription__wrapper-img {
    width: 40%;
    display: flex;
    justify-content: end;
    align-self: center;
  }
}

@media (width <= 1023px) {
  .form-subscription__container {
    flex: none;
    margin: 0;
  }

  .form-subscription {
    padding-bottom: 0;
  }

  .form-subscription__wrapper,
  .form-subscription__oneline-wrapper,
  .form-subscription__row {
    flex-direction: row;

    .andes-form-control--textfield .andes-form-control---validated-icon {
      position: absolute;
    }

    .andes-form-control--textfield.andes-form-control--readonly
      .andes-form-control__control {
      background-color: $white;
    }

    .andes-form-control {
      margin: 0 16px 6px;
      padding: 0;
      align-items: flex-start;
    }

    .andes-form-control--textfield .andes-form-control__control {
      width: 326px;
      height: 32px;
    }
  }

  .form-subscription__oneline-wrapper {
    .andes-form-control--readonly .andes-form-control__field {
      padding-left: 16px;
      font-size: 12px;
      margin: 0;
      color: rgb(0 0 0 / 55%);
    }

    .andes-form-control__border {
      background-color: rgb(0 0 0 / 0%);
    }

    p {
      margin: 0;
    }
  }

  .andes-form-control {
    margin-top: 18px;
    margin-bottom: 6px;
  }

  .form-subscription__container {
    margin-bottom: 5px;
  }

  .form-subscription__email {
    width: 70px;
    height: 70px;
  }
}

.form-subscription-link-login,
.form-subscription-link-register {
  color: $blue-200;
  text-decoration: none;
}

.social-network__full-wrapper {
  display: flex;
  justify-content: center;

  @media (width >= 1024px) {
    justify-content: flex-end;
  }
}
