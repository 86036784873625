// sass-lint:disable no-ids

@import '../../styles/variables';
@import '../content/content';
@import '../../components/CommunityView/community';
@import '../../components/FooterView/footer';
@import './components/HeaderView/styles';

.inner-content {
  min-height: calc(100vh - 116px);
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width <= 768px) {
    margin-bottom: 0;
  }
}

.partner-container {
  position: relative;
  width: 100%;
  max-width: 1200px;

  @media (width <= 768px) {
    min-height: auto !important;
    display: flex;
    flex-direction: column;
  }
}

.partner-container-left {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  max-width: 720px;

  @media (width <= 768px) {
    position: relative;
    top: auto !important;
    left: auto !important;
    padding: 24px 24px 32px;
  }
}

.partner-white-left {
  left: 0;
  top: 0;
  max-width: 720px;

  .partner-subtitle {
    @media (width <= 768px) {
      position: absolute;
      top: 24px;
      left: 24px;
    }
  }
}

.partner-container-right {
  position: absolute;
  right: 0;
  top: 0;

  img {
    @media (width <= 768px) {
      max-width: 100%;
      height: auto;
    }
    @media (width <= 500px) {
      max-width: none;
      width: auto;
      height: 273px;
      margin-left: -175px;
    }
    @media (width <= 420px) {
      margin-left: -252px;
    }
    @media (width <= 335px) {
      margin-left: -300px;
    }
  }

  @media (width <= 768px) {
    position: relative;
    order: -1;
  }
}

.partner-white-right {
  right: 0;
  top: 0;

  img {
    @media (width <= 768px) {
      max-width: 60%;
      height: auto;
    }
  }

  @media (width <= 768px) {
    overflow: hidden;
    margin-top: 24px;
    margin-bottom: 24px;
    order: -1;
  }
}

.partner-big-title {
  margin: 0;
  font-size: 44px;
  font-weight: 600;
  line-height: 1.14;
  color: $gray-80;

  @media (width <= 768px) {
    font-size: 26px;
    line-height: 30px;
  }
}

.partner-subtitle-highlight {
  margin: 8px 0 32px;
  font-size: 24px;
  color: $gray-80;
  padding: 5px 8px 10px 10px;
  background-color: $yellow-certification;

  @media (width <= 768px) {
    font-size: 16px;
    line-height: 20px;
    background-color: transparent;
    margin-bottom: 20px;
    padding: 0;
  }
}

.partner-subtitle-highlight-solution {
  font-size: 20px;
  color: $gray-80;
  background-color: $yellow-certification;
}

.partner-gray-area {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $gray-885;
  padding-bottom: 68px;

  .partner-card {
    @media (width <= 768px) {
      background: transparent;
      border-radius: 0;
      box-shadow: none;
    }
  }

  @media (width <= 768px) {
    background: $bg-list-gray;
    padding-bottom: 0;
  }
}

.partner-card {
  margin: 0 0 29px;
  padding: 48px;
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 $tap-highlight-color;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width <= 768px) {
    margin: 0 !important;
    padding: 24px;
  }
}

.partner-gray-area-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $gray-885;
}

.card-paragraph {
  font-size: 16px;
  max-width: 100%;
  margin: 24px 0 0;
  color: $gray-80;
}

.card-small-paragraph {
  font-size: 16px;
  max-width: 100%;
  margin: 0 0 15px;
  color: $gray-80;

  @media (width <= 768px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.card-title-paragraph {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  max-width: 100%;
  margin: 0;
  padding: 0;
  color: $gray-80;
}

.partner-title {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  color: $gray-80;

  @media (width <= 768px) {
    font-size: 18px;
    line-height: 22px;
  }
}

.partner-link-cards-container {
  width: 100%;
  margin-top: 76px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (width <= 768px) {
    margin-top: 20px;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
  }
}

.partner-link-card {
  height: 194px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $blue-100;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid $tap-highlight-color;

  span {
    max-width: 194px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: $black-090-opacity;

    @media (width <= 768px) {
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
    }
  }

  img {
    margin-bottom: 20px;
  }

  @media (width <= 768px) {
    height: 160px;
    background: $white;
    border: none;
  }
}

.partner-small-dots {
  width: 100%;
  position: relative;
  padding: 46px 0 24px;

  .partner-container {
    margin: 0 auto;
    text-align: center;
    min-height: unset;
  }

  img {
    @media (width <= 768px) {
      max-width: 100%;
      height: auto;
    }
  }

  @media (width >= 768px) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 136px;
      left: 0;
      height: 145px;
      width: 98px;
      background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/partner-center/306415691328-group-29-copy-4-3x.png');
      background-size: cover;
      background-repeat: no-repeat;
    }

    &::after {
      left: unset;
      right: 0;
    }
  }

  @media (width <= 768px) {
    background: $bg-list-gray;
    padding: 24px;
  }
}

.partner-paragraph {
  font-size: 18px;
  max-width: 634px;
  margin: 24px auto;
  color: $gray-80;

  @media (width <= 768px) {
    font-size: 12px;
    line-height: 15px;
    margin: 20px 0 0;

    & + .partner-paragraph {
      margin-bottom: 40px;
    }
  }
}

.partner-blue-section {
  width: 100%;
  background-color: $bg-blue-section;
  min-height: 221px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .partner-title {
    color: $white;
    margin-bottom: 32px;
    padding-top: 0;

    @media (width <= 768px) {
      margin-bottom: 18px;
    }
  }

  @media (width <= 768px) {
    min-height: 176px;
  }

  @media (width >= 768px) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 38px;
      left: 0;
      display: block;
      height: 145px;
      width: 403px;
      background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/partner-center/306415899012-group-29-copy-3-3x.png');
      background-size: cover;
      background-repeat: no-repeat;
    }

    &::after {
      left: unset;
      right: 0;
    }
  }
}

.partner-pages-title {
  display: flex;
  flex-direction: column-reverse;
}

.partner-subtitle {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.25;
  color: $gray-80;

  @media (width <= 768px) {
    font-size: 18px;
    line-height: 22px;
  }
}

.partner-big-subtitle {
  position: relative;

  @media (width >= 768px) {
    padding-top: 32px;

    &::before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: -8px;
      height: 8px;
      width: 103px;
      background-color: $yellow-certification;
    }
  }
}

.partner-description {
  margin: 24px 0 56px;
  font-size: 22px;
  color: $gray-80;
  max-width: 674px;

  @media (width <= 768px) {
    font-size: 16px;
    line-height: 20px;
    margin: 16px 0;
  }
}

.partner-breadcrumbs-container {
  margin: 30px 0 80px;
  width: 100%;
}

.partner-breadcrumbs {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1200px;
  display: flex;

  li {
    font-size: 14px;
    line-height: 1.29;
    color: $gray-80;
    display: flex;

    + li::before {
      content: '';
      margin: 1px 6px 0;
      width: 16px;
      height: 16px;
      display: inline-block;
      background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/partner-center/306394536407-16-px-3x.png');
      background-size: cover;
      background-repeat: no-repeat;
    }

    a {
      font-weight: 600;
      color: $blue-100;
      text-decoration: none;
    }
  }
}

.partner-gradient-area {
  background-image: linear-gradient(
    295deg,
    $bg-dark-gray-gradient 53%,
    $bg-light-gray-gradient
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 0;

  .partner-card {
    box-sizing: border-box;
    width: 348px;
    padding: 40px 52px 56px;

    img {
      @media (width <= 768px) {
        width: 54px;
        height: 54px;
        margin-top: 18px;
      }
    }

    p {
      margin: 24px 0 0;
      font-size: 18px;
      line-height: 1.22;
      text-align: center;
      color: $gray-80;

      @media (width <= 768px) {
        font-size: 12px;
        line-height: 15px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    @media (width <= 768px) {
      width: calc(50% - 4px);
      height: 184px;
      box-shadow: none;
      border-radius: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }

  @media (width <= 768px) {
    padding: 32px 24px;
  }
}

.partner-gray-paragraph {
  margin: 16px 0 64px;
  font-size: 18px;
  line-height: 1.11;
  text-align: center;
  color: $gray-45;

  @media (width <= 768px) {
    font-size: 12px;
    line-height: 15px;
    margin: 16px 0 12px;
  }
}

.partner-cards-container {
  display: flex;
  justify-content: space-evenly;

  @media (width <= 768px) {
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 8px 8px;
  }
}

.partner-content-section {
  width: 100%;
  padding: 96px 0 120px;

  .partner-subtitle {
    @media (width <= 768px) {
      margin-bottom: 16px !important;
    }
  }

  .partner-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (width <= 768px) {
    padding: 24px;
  }
}

.partner-white {
  position: relative;
  width: 100%;
  padding: 20px 0 100px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: initial;
  justify-content: space-between;
  max-width: 1200px;

  @media (width <= 768px) {
    flex-direction: column;
    padding: 24px;
  }
}

.partner-checklist {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    background-color: $bg-list-gray;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: $gray-80;
    margin-bottom: 1px;
    width: 588px;
    height: 69px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 23px;
      height: 23px;
      margin: 0 17px 0 24px;
      background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/partner-center/306386175912-bullets-b-3x.png');
      background-size: cover;
      background-repeat: no-repeat;
    }

    @media (width <= 768px) {
      width: auto;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (width <= 768px) {
    width: 100%;
  }
}

.partner-breadcrumbs-container.requisitos {
  margin: 30px 0 40px;
}

.partner-container-list {
  max-width: 1200px;
  margin: auto;
}

.partner-card.center {
  max-width: 1200px;
  margin: auto;
}

.card-small-paragraph.with-button {
  max-width: 80%;
}

.partner-button {
  display: flex;
  align-items: center;

  a {
    @media (width <= 768px) {
      width: 100%;
      margin: 0 0 10px !important;
    }
  }

  @media (width <= 768px) {
    flex-direction: column;
  }
}

.andes-button.andes-button--filled.partner {
  max-height: 50px;
  margin-top: 15px;
  width: 100%;
  max-width: 260px;
}

.andes-button.andes-button--quiet {
  margin-left: 8px;
}

.inner-content {
  min-height: calc(100vh - 116px);
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width <= 768px) {
    margin-bottom: 0;
  }
}

.solution-list__header {
  margin-top: 16px;
  text-align: center;
  width: 100%;
}

.solution-list__title {
  font-size: 34px;
  font-weight: bold;
  margin: 16px 20px;
  color: $gray-80;
}

.solution-list__subtitle {
  font-size: 22px;
  font-weight: normal;
  color: $gray-80;
  max-width: 562px;
  margin: 0 auto 25px;
  padding: 0;
}

.andes-card {
  border-radius: 0;
  box-shadow: none;
}

.solution-card {
  line-height: 1;
  text-align: center;

  .solution-card__info {
    margin: 24px;
    display: inline-block;
    max-width: 240px;
  }

  .solution-card__image {
    text-align: center;

    img {
      width: 127px;
      height: 127px;
      box-shadow: 0 0 1px 1px $black-007-opacity, 0 0 0 0 $black-025-opacity;
    }
  }

  .solution-card__details {
    vertical-align: top;
    text-align: left;
  }

  .solution-card__title {
    margin: 18px 0 13px;
    font-size: 22px;
    font-weight: 600;
    color: $gray-200;
    text-align: center;
    padding: 0;
  }

  .solution-card__contact {
    margin-top: 10px;
    color: $gray-200;

    p {
      font-size: 14px;
      display: block;
      font-weight: 600;
    }

    .solution-card__content {
      color: $link-color;
      margin-left: 5px;
      text-decoration: none;
      font-weight: normal;
    }
  }
}

.solution-card-center__image {
  text-align: center;

  img {
    box-shadow: 0 0 1px 1px $black-007-opacity, 0 0 0 0 $black-025-opacity;
  }
}

.solution-card__container {
  position: relative;
}

.solution-card__container .platinum,
.solution-card__container .gold,
.solution-card__container .silver,
.solution-card__container .certified {
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px 8px 4px 10px;
  border-bottom-left-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1.67;

  .level__icon {
    margin-right: 4px;
  }
  @media (width <= 768px) {
    border-radius: 16px;
  }
}

.solution-card__container .platinum {
  background-color: $platinum-bg;
  color: $platinum-font;

  .level__icon {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/324277804513-small-platinum.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 17px;
    height: 18px;
  }
}

.solution-card__container .gold {
  background-color: $gold-bg;
  color: $gold-font;

  .level__icon {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/324278021525-small-gold.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 15px;
    height: 18px;
  }
}

.solution-card__container .silver {
  background-color: $gray-500;
  color: $gray-999;

  .level__icon {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/320143761532-medalla-silver-small.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 15px;
    height: 18px;
  }
}

.partner-solution-icon {
  display: flex;

  img {
    padding-right: 8px;
  }
}

// Styles for Landing Hub - Home
// CAROUSEL
.landinghub-home-carousel__title {
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  max-width: 608px;
  color: $black-090-opacity;
  margin-top: 0;
}

.landinghub-home-carousel__subtitle {
  font-size: 16px;
  line-height: 20px;
  color: $black-090-opacity;
  margin-bottom: 24px;
}

.landinghub-home-carousel__image {
  width: 318px;
  height: 215px;
  margin: 20px auto 0;
  display: block;
}

.landinghub-home-carousel__logo {
  background-color: $white;
  border-radius: 5px;
  position: absolute;
  width: 115px;
  height: 44px;
  right: 0;
  bottom: 4%;
}

.landinghub-home-carousel__texts-container {
  margin-top: 60px;
}

.landinghub-home-carousel__texts-without-cta-container {
  margin-top: 75px;
}

.landinghub-home-carousel__texts-without-cta-container > p {
  margin-bottom: 65px;
}

///////
.landinghub-home-carousel__background {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  background: $bg-list-gray;
}

.landinghub-home-carousel__container {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  background: $bg-list-gray;
}

.slider {
  width: 320px;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
}

.slides {
  width: 500%;
  height: 500px;
  display: flex;
}

.slides input {
  display: none;
}

.slide {
  width: 20%;
  transition: 1s;
}

.slide > div {
  width: 320px;
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.navigation-manual {
  position: absolute;
  width: 320px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.manual-btn {
  background: $black-01-opacity;
  width: 6px;
  height: 6px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}

.manual-btn:not(:last-child) {
  margin-right: 40px;
}

.manual-btn:hover {
  background: $blue-100;
  border: 1px solid #f5f5f5;
}

#radio1:checked ~ .first {
  margin-left: 0;
}

#radio2:checked ~ .first {
  margin-left: -20%;
}

#radio3:checked ~ .first {
  margin-left: -40%;
}

// Automatic transition for carousel banners
.navigation-auto {
  position: absolute;
  display: flex;
  width: 320px;
  justify-content: center;
  margin-top: 510px;
}

.navigation-auto > div {
  background: $black-01-opacity;
  width: 6px;
  height: 6px;
  padding: 5px;
  border-radius: 10px;
  transition: 1s;
}

.navigation-auto > div:not(:last-child) {
  margin-right: 40px;
}

#radio1:checked ~ .navigation-auto .auto-btn1 {
  // sass-lint:disable-line no-ids id-name-format
  background: #3483fa;
  border: 1px solid #f5f5f5;
}

#radio2:checked ~ .navigation-auto .auto-btn2 {
  // sass-lint:disable-line no-ids id-name-format
  background: #3483fa;
  border: 1px solid #f5f5f5;
}

#radio3:checked ~ .navigation-auto .auto-btn3 {
  // sass-lint:disable-line no-ids id-name-format
  background: #3483fa;
  border: 1px solid #f5f5f5;
}

// EXPERTS DESCRIPTION
.landinghub-home-experts-description__container {
  background-color: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5.5rem 0;
}

.landinghub-home-experts-description__container > h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  color: $black-090-opacity;
  padding: 0 24px;
  margin: 0;
}

.landinghub-home-experts-description__container > p {
  font-size: 16px;
  line-height: 22px;
  color: $black-090-opacity;
  max-width: 827px;
  margin: 2rem 0 2.875rem;
  word-wrap: break-word;
  text-align: center;
  padding: 0 20px;
}

.landinghub-home-experts-description__cards-container {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
  padding: 0 20px;

  .partner-card {
    position: relative;
    margin: 0;
    padding: 26px;
    height: 100%;
  }

  .partner-card:hover {
    background-color: $blue-100;
    transition: background-color 0.2s ease-in;
    margin: 0;

    .partner-card:hover,
    .landinghub-home-experts-description__cards-img,
    .landinghub-home-experts-description__cards-text,
    .landinghub-home-experts-description__cards-logos-meli,
    .landinghub-home-experts-description__cards-logos-shops {
      opacity: 0;
    }

    .partner-card:hover,
    .landinghub-home-experts-description__cards-hover {
      opacity: 1;
    }
  }
}

.landinghub-home-experts-description__cards-hover {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  line-height: 20px;
  word-wrap: break-word;
  color: $white;
  opacity: 0;
}

.landinghub-home-experts-description__cards-img {
  max-height: 86px;
  opacity: 1;
}

.landinghub-home-experts-description__cards-text {
  font-size: 16px;
  line-height: 20px;
  height: 100%;
  max-width: 176px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 0 2.5rem;
}

.landinghub-home-experts-description__cards-logos {
  display: flex;
  position: absolute;
  bottom: 26px;
  height: 25px;
  margin-top: 0.5rem;

  :first-child {
    margin-right: 1rem;
  }
}

.landinghub-home-experts-description__cards-logos-shops,
.landinghub-home-experts-description__cards-logos-meli {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/237969649071-Mercado-Libre.png');
  width: 34px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

// EVENTS
.landinghub-home-events__container {
  background-color: $gray-885;
  width: 100%;
  padding: 5.5rem 24px;
}

.landinghub-home-events-maxwidth__container > h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: $black-090-opacity;
  margin: 0 0 1rem;
  padding: 0;
}

.landinghub-home-events-maxwidth__container > p {
  font-size: 18px;
  line-height: 22px;
  color: $black-090-opacity;
  margin: 0 0 2.125rem;
}

.landinghub-home-events__cards-container {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: 1fr;
  grid-template-rows: fit-content(40%) fit-content(40%) 1fr;
}

.landinghub-home-events__card {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 1px 2px $black-012-opacity;
  position: relative;
  height: 480px;
}

.landinghub-home-events__card:hover {
  cursor: pointer;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);
}

.landinghub-home-events__card:link,
.landinghub-home-events__card:visited,
.landinghub-home-events__card:active {
  text-decoration: none;
  color: $black-090-opacity;
  font-weight: 200;
}

.landinghub-home-events__card-top-container {
  position: relative;
}

.landinghub-home-events__card-image {
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.landinghub-home-events__card-logos-container {
  display: flex;
  background-color: $white;
  position: absolute;
  right: 0;
  top: 10%;
  padding: 8px 18px 8px 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.landinghub-home_logos__container {
  display: flex;
  background-color: $white;
  margin-bottom: 25px;
  justify-content: center;
}

.landinghub-home-events__card-logos-container > div {
  width: 34px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 8px;
}

.landinghub-home_logos__container > div {
  width: 34px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

.landinghub-home_logos__container > div:first-child {
  margin-right: 8px;
}

.landinghub-home-events__card-logos-shops,
.landinghub-home-events__card-logos-meli {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/237969649071-Mercado-Libre.png');
}

.landinghub-home-events__card-all-texts {
  padding: 1rem 1.5rem 1.125rem;
}

.landinghub-home-events__card-title {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: $black-090-opacity;
  margin: 1.5rem 0 0.875rem;
  padding: 0;
}

.landinghub-home-events__card-paragraph {
  font-size: 16px;
  line-height: 20px;
  color: $black-090-opacity;
  margin-bottom: 1.313rem;
}

.landinghub-home-events__card-info-date {
  display: flex;
  align-items: center;
  margin-right: 1.125rem;
  position: absolute;
  bottom: 17%;
}

.landinghub-home-events__card-info-date > img {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.landinghub-home-events__card-info-date > p {
  font-size: 16px;
  line-height: 20px;
  color: $black-090-opacity;
}

.landinghub-home-events__card-price-container {
  border-top: 1px solid $tap-highlight-color;
  padding: 1.25rem 1.5rem 1.125rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  position: absolute;
  bottom: 0;
}

.landinghub-home-events__card-price-title,
.landinghub-home-events__card-price-number {
  font-size: 16px;
  line-height: 20px;
}

.landinghub-home-events__card-price-title {
  font-weight: 200;
}

// EXPERTS FILTER
.landinghub-home-experts-filter__container {
  background-color: $white;
  width: 100%;
  padding: 5.5rem 24px;
  align-items: center;
  text-align: center;
  color: $black-090-opacity;
}

.landinghub-home-experts-filter__title {
  font-size: 25px;
  line-height: 29px;
  font-weight: 600;
  margin-bottom: 1.938rem;
}

.landinghub-home-experts-filter__subtitle {
  font-size: 31px;
  line-height: 34px;
  font-weight: 300;
  margin-bottom: 3.125rem;
  padding: 0 20px;
}

.landinghub-home-experts-filter__numbers-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 7.375rem;
}

.landinghub-home-experts-filter__numbers > h1 {
  font-size: 32px;
  line-height: 22px;
  font-weight: 600;
  padding: 0;
  margin-bottom: 1rem;
}

.landinghub-home-experts-filter__numbers > h3 {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

// VIDEO
.landinghub-home-video__container {
  background-color: $bg-blue-background;
  width: 100%;
  height: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5.5rem 1.25rem;
}

.landinghub-home-video__container > h2 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: $black-090-opacity;
  padding: 0 20px;
  margin: 0;
}

.landinghub-home-video__container > p {
  font-size: 16px;
  line-height: 20px;
  color: $black-090-opacity;
  max-width: 827px;
  margin: 2rem 0 2.875rem;
}

.landinghub-home-video__iframe {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.landinghub-home-video__iframe > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.landinghub-home-video__container > a {
  margin-top: 2rem;
}

// PROGRAMS
.landinghub-home-programs__container {
  background-color: $white;
  height: 100%;
  width: 100%;
  padding: 56px 20px 0;
}

.landinghub-home-programs__item {
  text-align: center;
}

.landinghub-home-programs__item-icon {
  height: 130px;
}

.landinghub-home-programs__item > h3 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: $black-090-opacity;
  margin: 1rem 0 1.5rem;
  padding: 0;
}

.landinghub-home-programs__item > p {
  font-size: 16px;
  line-height: 20px;
  color: $black-090-opacity;
}

.landinghub-home-programs__item > .andes-button {
  margin: 16px 0 64px;
}

// Styles for Landing Hub - Events Page
.landinghub-event__grey-background {
  background-color: $white;
  padding: 0;
}

.partner-breadcrumbs-container {
  display: none;
}

.landinghub-event-video__iframe {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.landinghub-event-video__iframe > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 450px;
  padding: 0 2rem;
}

.landinghub-event-image {
  position: relative;
  width: 100%;
  max-width: 719px;
  display: flex;
  justify-content: center;
  padding: 0 32px;
}

.landinghub-event-image > img {
  height: 100%;
  width: 100%;
}

.landinghub-event__container {
  background-color: $white;
  border-radius: 3px;
}

.landinghub-event__mainInfo__container > h4 {
  font-size: 20px;
  line-height: 25px;
  padding: 0 2.5rem;
  margin-top: 3.375rem;
}

.landinghub-event__mainInfo__all-texts {
  padding: 0 3.125rem 3.125rem;
}

.landinghub-event__mainInfo__table {
  width: 100%;
}

.landinghub-event__mainInfo__table,
th,
td {
  border: 1px solid $tap-highlight-color;
  border-collapse: collapse;
  font-size: 14px;
  line-height: 40px;
  text-align: start;
  padding-left: 1rem;
}

tr:nth-child(odd) {
  background-color: $black-004-opacity;
}

.landinghub-event__mainInfo__all-texts > h3 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  margin: 2.5rem 0 0;
  padding: 0;
}

.landinghub-event__mainInfo__all-texts > p {
  margin-top: 20px;
}

.landinghub-event__mainInfo__all-texts > h4 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  padding-top: 0;
  margin-top: 2rem;
}

.landinghub-event__mainInfo__numbered-list,
.landinghub-event__mainInfo__bullets-list {
  padding: 0;
}

.landinghub-event__mainInfo__numbered-list > li {
  list-style: decimal;
  list-style-position: inside;
}

.landinghub-event__mainInfo__bullets-list > li {
  list-style: disc;
  list-style-position: inside;
  display: list-item;
  padding: 0;

  li::marker {
    margin-right: 18px;
  }
}

.landinghub-event__sideInfo-all-texts {
  padding: 2.5rem 2rem 1.25rem;
  border-bottom: 1px solid $tap-highlight-color;
}

.landinghub-event__sideInfo-all-texts > h3 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  max-width: 470px;
  color: $black-090-opacity;
  margin-top: 0;
  padding: 0;
}

.landinghub-event__sideInfo-all-texts > p {
  padding: 0;
  margin-top: 2.5rem;
}

.landinghub-event__sideInfo-all-texts > a {
  margin-top: 1.25rem;
  width: 100%;
}

.landinghub-event__sideInfo-text-price__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
}

.landinghub-event__sideInfo-badge-container {
  width: 100%;
  text-align: end;

  .andes-badge__content {
    color: black;
  }
}

.landinghub-event__sideInfo-text {
  padding-right: 2rem;
  max-width: 160px;
}

.landinghub-event__mainInfo__all-texts > p,
.landinghub-event__mainInfo__numbered-list > li,
.landinghub-event__mainInfo__bullets-list > li,
.landinghub-event__sideInfo-text,
.landinghub-event__sideInfo-logos-title,
.landinghub-event__sideInfo-all-texts > p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: $black-090-opacity;
}

.landinghub-event__mainInfo__numbered-list > li > span {
  position: relative;
  right: -18px;
}

.landinghub-event__sideInfo-observation {
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  max-width: 470px;
  margin-top: 8px;
  color: $black-090-opacity;
}

.landinghub-event__sideInfo-logos-title {
  margin-bottom: 8px;
  width: 180px;
}

.landinghub-event__sideInfo-price {
  font-size: 26px;
  line-height: 35px;
  font-weight: 300;
  text-align: right;
  display: flex;
  align-items: center;
}

.landinghub-event__sideInfo-price-iva {
  font-size: 12px;
  line-height: 15px;
  color: $black-055-opacity;
}

.landinghub-event__sideInfo-logos-left-container {
  max-width: 180px;
}

.landinghub-event__sideInfo-logos {
  display: flex;
  padding: 2.188rem;
}

.landinghub-event__sideInfo-logos > img {
  width: 89px;
  max-height: 65px;
  margin-right: 1.5rem;
}

.landinghub-event__sideInfo-small-logos__container {
  display: flex;

  :first-child {
    margin-right: 10px;
  }
}

.landinghub-event__sideInfo-small-logos__container {
  display: flex;

  :first-child {
    margin-right: 10px;
  }
}

.landinghub-event__sideInfo-logos-shops,
.landinghub-event__sideInfo-logos-meli {
  width: 34px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

.landinghub-event__sideInfo-logos-shops,
.landinghub-event__sideInfo-logos-meli {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/DevImgs/238921061305-Mercado-Libre.png');
}

// Styles for Tabs - Solutions in Landing Hub

.section__container {
  margin: 0;
  width: calc(100% - 40px);
  max-width: 1144px;
  color: rgb(0 0 0 / 80%);
}

.section__container-relative {
  color: rgb(0 0 0 / 80%);
  margin: 0 auto;
  width: 100%;
  max-width: 1144px;
  position: relative;
  height: 100%;
}

.section__solutions-title {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.25;
  margin: 0 0 24px;
  padding: 0;
  text-align: initial;
}

.section__solutions-paragraph {
  font-size: 24px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.25;
  padding: 0;
  margin: 0 0 58px;
  max-width: 864px;
}

.section__solutions-table {
  border: 1px solid #ededed;
  display: flex;
  margin-bottom: 64px;
  @media (width <= 840px) {
    border: 0;
    flex-direction: column;
    margin-bottom: 0;
  }
}

.solution__list {
  margin: 0;
  padding: 0;
  min-width: 225px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  @media (width <= 840px) {
    border-radius: 3px;
    border: 1px solid #fefefe;
    box-shadow: 0 0.1875em 0.25em 0 $black-01-opacity,
      0 0 0.0625em 0 rgb(0 0 0 / 25%);
  }
}

.solution__selector {
  display: none;
  background-color: #3483fa;
  border-radius: 8px;
  margin-left: -13px;
  margin-right: 6px;
  position: absolute;
  height: 85%;
  width: 3px;
}

.solution__list-item {
  position: relative;
  vertical-align: middle;
  padding: 12px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29px;
  border-right: 1px solid #ededed;
  flex: 1 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 270px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ededed;
    @media (width <= 840px) {
      border: 0;
    }
  }

  &.active {
    border-right: 0;
    color: #3483fa;

    .solution__selector {
      display: block;
    }
    @media (width <= 840px) {
      display: flex;
    }
  }

  &.active::before,
  &.active::after {
    content: '';
    position: absolute;
    border: 24px solid transparent;
    border-left-color: #fff;
    right: calc(2 * -24px);
    @media (width <= 840px) {
      border: 0;
    }
  }

  &.active::before {
    border-width: 25px;
    border-left-color: #ededed;
    right: calc(2 * -25px);
    @media (width <= 840px) {
      border: 0;
    }
  }
  @media (width <= 840px) {
    border: 0;
    display: none;
  }
}

.solution__arrow-down,
.solution__arrow-up {
  display: none;
  position: absolute;
  right: 0;
  height: 50%;
  width: 32px;
  align-items: flex-end;
  justify-content: center;
  @media (width <= 840px) {
    display: flex;
  }
}

.solution__arrow-down img,
.solution__arrow-up img {
  width: 16px;
}

.solution__arrow-down img {
  transform: rotate(180deg);
}

.solution__arrow-down {
  bottom: 0;
  align-items: flex-start;
}

.solution__list-stage {
  display: none;
  padding: 42px 42px 42px 180px;

  &.active {
    display: block;
    width: 100%;
    position: relative;
    max-width: 810px;
  }
  @media (width <= 1300px) {
    padding-left: 150px;
  }
  @media (width <= 840px) {
    padding: 18px 0 36px;
  }
}

.solution__list-stage-title {
  margin: 0;
  margin-bottom: 18px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  padding-top: 20px;
  text-align: start;
  @media (width <= 840px) {
    display: none;
  }
}

.solution__list-stage-paragraph {
  margin: 0;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 1.44;
  font-weight: normal;
  max-width: 510px;
  text-align: start;
  @media (width <= 840px) {
    font-size: 14px;
    width: 100%;
  }
}

.solution__list-stage-guides {
  list-style: none;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
}

.solution__link {
  text-decoration: none;
  font-size: 14px;
  color: #3483fa;
  margin: 5px 25px 5px 0;
  display: block;
}

/////////

@media (width <= 767px) {
  .section__solutions {
    margin-top: 92px;
    width: 100%;
  }

  .section__solutions-title {
    font-size: 20px;
    margin: 0 0 10px;
    padding: 0;
    text-align: initial;
  }

  .section__solutions-paragraph {
    font-size: 16px;
    margin: 0 0 32px;
  }
}

.landinghub-home_filterSF__button-see-all > .andes-button.andes-button--quiet {
  width: 100%;
  margin-top: 20px;
  margin-left: 0;
  display: flex;
  align-items: center;
}

@media (width <= 840px) {
  .solution__list.dropdown__open {
    position: fixed;
    width: 100%;
    background-color: #fff;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;

    .solution__list-item {
      display: flex;
      border-radius: 0;
      box-shadow: none;

      &:not(:last-of-type) {
        border-bottom: 1px solid #ededed;
      }
    }

    .solution__arrow-down,
    .solution__arrow-up {
      display: none;
    }
  }
}

.icon-publica {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/partner-center/280292625446-Icon-Consultoria.png');
  background-size: cover;
  height: 30px;
  width: 40px;
}

.icon-gestionar {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/partner-center/280292591457-Icon-Marketing.png');
  background-size: cover;
  height: 38px;
  width: 40px;
}

.icon-administra {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/partner-center/280292582353-Icon-Operadores.png');
  background-size: cover;
  height: 30px;
  width: 40px;
}

.icon-productos {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/partner-center/280292608521-Icon-Fotografos.png');
  background-size: cover;
  height: 30px;
  width: 45px;
}

.icon-diseno {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/partner-center/275282485509-icon-disen-o.png');
  background-size: cover;
  height: 28px;
  width: 32px;
}

.landinghub-home_solutions__container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: fit-content(40%) fit-content(40%) 1fr;
}

.landinghub-home_filterSF__solution-logo {
  height: 150px;
  max-width: 150px;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 1px 2px $black-012-opacity;
}

.landinghub-home_filterSF__solution-logo:hover {
  cursor: pointer;
  box-shadow: 0 0 4px 0 $black-03-opacity;
  transition: box-shadow 0.25s ease-in-out;
}

.landinghub-home_solution-icon-logos__container {
  display: flex;
  width: 100%;
  border-top: 1px solid $tap-highlight-color;
  padding: 1rem;
  justify-content: center;
  position: absolute;
  bottom: 0;

  :first-child {
    margin-right: 1.5rem;
  }
}

.landinghub-home_solution-icon-logos__container > img {
  width: 34px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

/* Delipop Dulcería */

.delipop__container {
  width: 360px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;

  .delipop__container__section {
    margin: 0 auto;
    padding: 16px 0;

    .delipo__container__section-title {
      line-height: 1.4;
      font-size: 40px;
      margin-bottom: 30px;
    }

    .delipop__container__section-list {
      .delipop__container__section-itens {
        margin-left: 63px;
        padding: 0;

        .delipop__container__section-item {
          font-size: 20px;
          margin-bottom: 10px;
          line-height: 1.4;
          color: rgb(0 0 0 / 80%);
        }
      }
    }
  }

  .delipop__container__section__brigada {
    .delipop__container__section__brigada-title {
      padding: 0;
      margin: 20px 0;
      font-size: 28px;
      line-height: 35px;
    }

    .delipop__container__section__brigada-paragraph {
      line-height: 1.4;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .delipop__container__section__brigada-video {
      margin: 20px 0;

      .delipop__container__section__brigada-player {
        width: 100%;
        height: 200px;
      }
    }

    .delipop__container__section__brigada-subscribe {
      margin-bottom: 20px;

      .delipop__container__section__brigada-subscribe-paragraph {
        font-size: 16px;
      }
    }

    .delipop__container__section__brigada-card {
      display: flex;
      flex-direction: column;
      margin-bottom: 60px;
      justify-content: center;
      margin-top: 30px;
      min-height: 288px;
      width: 330px;
      align-items: center;
      border-radius: 6px;
      box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%);

      .delipop__container__section__brigada-buttons-title {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
      }

      .delipop__container__section__brigada-buttons {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .delipop__container__section__brigada-button {
          max-width: 530px;
          width: 80%;
          background-color: #3483fa;
          border: none;
          border-radius: 6px;
          margin-top: 15px;
          min-height: 48px;
          padding: 16px 24px;

          .delipop__container__section__brigada-button-title {
            color: #fff;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
          }
        }
      }
    }
  }
}
