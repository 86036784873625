.mesh-row {
  position: unset;
}

.page-form {
  max-width: 900px;
  margin: 100px auto;

  .andes-widther {
    display: inline-block;
  }
}

section.content {
  padding-top: 56px;

  .mesh-row {
    margin-top: 25px;
  }
}

.page-header {
  top: 120px;

  h4 {
    font-size: 18px;
  }
}

.guide-selection a {
  padding: 15px 12px 16px;
  margin: 10px 20px;
}

.inner-content {
  display: block;

  img {
    max-width: fill-available;
  }
}

h3,
h4,
.mesh-row__content h2 {
  padding-top: 65px;
  margin-top: -65px;
}

.maintitle-security__bug-bounty {
  font-weight: 600;
  font-size: 20px;
}

.container-security__bug-bounty,
.details__message-tex-bug-bounty,
.details__bullets-list-bug-bounty,
.details__message p {
  font-size: 15px;
  font-weight: 400;
}

.details__bullets-list-bug-bounty {
  margin: 15px 0 15px 15px;
  padding: 0;
  list-style: none;

  li::before {
    content: '•';
    margin-right: 5px;
  }

  strong {
    font-weight: 600;
    font-size: 15px;
  }
}

.andes-table__header,
.andes-table__column {
  padding: 10px;
}

.andes-table__header,
.andes-table__column {
  display: table-cell;
  text-align: center;
  white-space: normal;
}

@media (width <= 1550px) {
  .andes-table__header,
  .andes-table__column,
  .andes-table__header--left {
    text-align: start;
  }
}

@media (width >= 1550px) {
  .andes-table {
    .andes-table__row {
      .andes-table__column {
        padding: 20px 15px;
        text-align: center;
      }
    }
  }
}

/* One Column styles--------------------------------------------------------------- */

.one-column {
  max-width: unset;
}

.mesh-row.one-column {
  margin-top: -25px;
}

.mesh-row__content {
  padding-bottom: 90px;
}

.mesh-col-l-9.mesh-row__content {
  margin-bottom: 100px;
}

.mesh-col-l-12.mesh-row__content {
  margin-bottom: 100px;
  display: block;
}

/* One Open Platform Landing page
	--------------------------------------------------------------- */

.section-content {
  margin: 0 auto;
  max-width: 970px;
}

.title-container {
  .section-title {
    font-size: 42px;
    font-weight: 100;
    text-align: center;
  }
}

.hide-desktop {
  display: none;
}

/* Banner
      --------------------------------------------------------------- */

.section-content.banner-content {
  background-color: #ebebeb;
  content: '';
  min-height: 907px;
  max-width: 100%;
  margin-top: 0;
  position: relative;
  width: 100%;
}

.section-content.banner-content::after {
  position: absolute;
  top: 98.1%;
  left: 70%;
  margin-left: -25%;
  content: '';
  width: 0;
  height: 0;
  border-top: solid 50px #ebebeb;
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
}

.banner-content__main-container {
  height: 807px;
  margin: 0 auto;
  width: 1072px;
  max-width: 100%;

  .banner-content__info-container {
    max-width: 100%;

    .banner-content__info-text {
      font-size: 16px;
      letter-spacing: 0.3px;
      line-height: 1.5;
      padding: 0;
    }
  }

  .banner-content_img-container {
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    height: 535px;

    .banner-content_img {
      background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/367995825201-Meli-ecosystem.png');
      width: 665px;
      height: 464px;
      max-height: 100%;
    }
  }
}

/* APIs--------------------------------------------------------------- */
.section-content.platforms {
  content: '';
  margin-bottom: 20px;
  padding: 5% 50px;
  position: relative;
  height: auto;
  width: 1280px;
  max-width: 100%;
}

.section-content.platforms::after {
  position: absolute;
  top: 101%;
  left: 70%;
  margin-left: -25%;
  content: '';
  width: 0;
  height: 0;
  border-top: solid 50px #fff;
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
}

.platform__info-content {
  background-color: inherit;
  opacity: 0;
  inset: 0;
  position: absolute;
  transition: all 0.2s ease-in-out;
  visibility: visible;

  a.platform__info-content-close {
    float: right;
    margin-top: 15px;
    margin-right: 15px;
    color: transparent;
  }

  p {
    line-height: 32px;
    padding: 85px 50px 0;
    text-align: center;
  }
}

.platform__transition-mob-container {
  display: none;
}

.meli-plat:hover,
.meli-plat:focus {
  background-color: #ffe600;
}

.ms:hover,
.ms:focus {
  background-color: #db344b;
}

.ms {
  .platform__info-content {
    p,
    .platform__info-p {
      padding-top: 85px;
    }
  }
}

.meli-plat:hover,
.platform:hover,
.platform-mlp:hover,
.platform-mlp:focus,
.meli-plat:focus,
.platform:focus {
  .platform__info-content,
  .platform__info-content.meli-plat {
    display: block;
    opacity: 1;
  }
}

.platform__info-content.meli-plat {
  top: 0;
  visibility: visible;
}

.meli-plat__subrpoducts-container {
  width: 257px;
  display: block;
  margin: 0 auto;
}

a.platform__docs {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 45px;
  margin: 0 auto;
  text-align: center;
  width: 200px;
  display: block;
}

.meli-plat {
  padding-bottom: 15px;
  height: 350px;

  .platform__info-content-close-container {
    opacity: 0;
  }

  .platform__logo-container {
    display: block;
    height: 265px;
    margin: 0;
    padding-top: 70px;

    .platform__logo {
      background-repeat: no-repeat;
      display: block;
      height: 135px;
      margin: 0 auto;
      width: 150px;
    }
  }

  .meli-plat__subrpoducts-container-wrapper {
    margin-top: 33px;
    margin-bottom: 22px;
  }

  p.platform__info-p {
    color: #333;
    margin: 0;
    height: 130px;
  }

  .platform__info-content,
  .platform__info-content {
    .platform__info-p,
    p {
      height: 130px;
    }
  }
}

.platform__info-content {
  p,
  .platform__info-p {
    color: #fff;
  }
}

.klp {
  display: block;
  margin: 0 26.5%;

  .platform__info-content {
    p,
    .platform__info-p {
      margin: 0;
    }
  }
}

.platform {
  margin-bottom: 20px;
  height: 350px;
  position: relative;
  width: 580px;
  min-width: 48.9%;
  max-width: 49%;

  .platform__logo-container {
    display: block;
    height: 265px;
    margin: 0;
    padding-top: 70px;

    .platform__logo {
      background-repeat: no-repeat;
      width: 175px;
      height: 175px;
      margin: 0 auto;
      display: block;
    }
  }

  .platform__info-content-close-container {
    display: none;
  }

  .platform__info-p {
    height: 265px;
    margin: 0;
    padding-top: 85px;
  }
}

.platform-mlp {
  margin-bottom: 20px;
  height: 350px;
  position: relative;

  .platform__logo-container {
    display: block;
    height: 265px;
    margin: 0;
    padding-top: 70px;

    .platform__logo {
      background-repeat: no-repeat;
      width: 175px;
      height: 175px;
      margin: 0 auto;
      display: block;
    }
  }

  .platform__info-content-close-container {
    display: none;
  }

  .platform__info-p {
    height: 265px;
    margin: 0;
    padding: 115px 90px 0;
  }
}

.platform__links-container {
  display: block;
  margin: auto;
  width: 200px;

  .platform__links-doc {
    border: solid 1px #000;
    color: #333;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    height: 45px;
    width: 200px;
  }
}

/* Countries
      --------------------------------------------------------------- */

.section-content.countries {
  height: 1114px;
  background-color: #f5f5f5;
  width: 100%;
  max-width: 100%;

  .countries__list-container {
    display: none;
  }

  .countries__img-container {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/367757237187-mapa-new.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 791px;
    margin: 0 auto;
    width: 890px;
    max-width: 100%;
  }
}

@media (width >= 900px) {
  /* Base/Shared
  --------------------------------------------------------------- */

  .nav-header {
    .nav__logo-container {
      padding-left: 43px;

      .oop-logo {
        width: 229px;
        margin: 7px 0;
      }
    }

    .nav__menu-container {
      margin-right: 60px;
    }
  }

  .meli-plat {
    p.platform__info-p {
      color: #333;
    }
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

// tamplate newsletter

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }

  .esc_custom .esc__section-banner {
    display: -webkit-box;
    display: flexbox;
    display: flex;
    height: 150px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff159;
    width: 100%;
    margin: -25px auto 0;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .d-md-flex {
    display: flexbox;
    display: flex;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 960px;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1140px;
  }
}

.col-auto-flag {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.vertical-img,
.horizontal-img {
  border-radius: 15px;
}

// DPP styles

.hero {
  height: 540px;
}

.hero_image img {
  height: 540px;
}

.section__benefits {
  padding: 88px 0;
}

.section__benefits .section__title {
  font-size: 28px;
  line-height: 1.25;
}

.section__benefits .section__description {
  font-size: 20px;
  line-height: 1.2;
}

.section__benefits .section__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 940px;
  margin: 48px auto;

  @media (width <= 900px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.hero__title {
  font-size: 48px;
}

.hero__description {
  font-size: 20px;
}

.section__medals .cta {
  margin-top: 45px;
}

.section-medals__content {
  max-width: 1036px;
  margin: 60px auto 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section-medals__paragraphs-wrapper {
  p {
    margin: 0;
    font-size: 20px;
    line-height: 1.2;
  }
}

.section__benefits::before {
  width: 105px;
  left: 0;
}

.section__benefits::after {
  width: 120px;
  right: 0;
}

.section__benefits::before,
.section__benefits::after {
  @media (width <= 1050px) {
    display: none;
  }
}

.section__benefits::before {
  @media (width <= 1170px) {
    width: 70px;
  }
}

.section__benefits .andes-card {
  width: 295px;
  padding: 26px 24px;
  @media (width <= 900px) {
  }
}

.section__banner {
  @media (width <= 1170px) {
    margin-bottom: 0;
  }
}

.section__banner {
  margin-bottom: -100px;
}

.banner__ctas {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.banner-title {
  font-size: 28px;
  line-height: 1.25;
}

.banner-description {
  font-size: 20px;
  line-height: 1.2;
}

.banner__ctas .cta .andes-button:link {
  padding: 30px;
  line-height: 1.3;
}

.cta__button,
.andes-button {
  padding: 30px;
  width: 350px;
}
