.user-photo {
  border-radius: 100%;
  height: 60px;
  width: 60px;
  z-index: 1;
}

.user-photo__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid #ffe91e;
  height: 68px;
  width: 68px;
  box-sizing: content-box;
  margin: 10px 14px;
  margin-right: 22px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -7px;
    background-color: #fff;
    width: 50%;
    height: 25%;
  }

  &::after {
    content: '</>';
    z-index: 2;
    position: absolute;
    right: -8px;
    bottom: -3px;
    border-radius: 100%;
    height: 28px;
    width: 28px;
    color: #fff;
    background-color: #3383fa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
