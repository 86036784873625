.supporthasbackoffice__app {
  height: 520px;
  left: 62%;
  margin-bottom: 0 !important;
  margin-left: -243px;
  margin-top: -250px;
  position: absolute;
  top: 50%;
  transform: translate(0%, 0%);
  width: 400px;
}

.supporthasbackoffice__app > svg {
  margin-left: 20%;
  margin-bottom: 5%;
}

.supporthasbackoffice_body__app {
  margin-block-end: 50px;
  transform: translate(-5%, 0%);
}

.supporthasbackoffice_body__app > h2 {
  font-size: 18px !important;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 42px;
  padding-top: 0 !important;
  text-align: center;
}

.supporthasbackoffice_body__app > p {
  color: black;
  margin-left: 65px;
  margin-right: 50px;
}

.redirectml_button__app,
.redirectmp_button__app {
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.redirectmp_button__app {
  background-color: rgb(65 137 230 / 15%);
  color: #3483fa;
  margin-top: 55px !important;
}

.supporthasbackoffice_body__app > ol > li {
  margin-left: 35px;
  margin-right: 30px;
}

.supporthasbackoffice_body__app > ol > li > svg {
  vertical-align: text-bottom;
}

@media screen and (width <= 368px) {
  .supporthasbackoffice_body__app > p {
    margin: 10px;
    width: 100%;
  }

  .redirectml_button__app,
  .redirectmp_button__app {
    width: 100%;
  }

  .redirectmp_button__app {
    background-color: rgb(65 137 230 / 15%);
    color: #3483fa;
    top: 80%;
  }
}
