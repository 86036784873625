// Styleguide Variables.Colors

$white: #fff;
$black: #000;
$transparent: rgba($white, 0);
$transparent-half: rgba($white, 0.5);

// Colors: Grays
//
// $gray-000 - #000
// $gray-100 - #111
// $gray-200 - #333
// $gray-300 - #666
// $gray-400 - #999
// $gray-500 - #cbcbcb
// $gray-600 - #ccc
// $gray-650 - #c0c0c0
// $gray-700 - #d8d8d8
// $gray-750 - #ddd
// $gray-800 - #eee
// $gray-825 - #e6e6e6
// $gray-850 - #f0f0f0
// $gray-885 - #f5f5f5
// $gray-900 - #f7f7f7
// $gray-1000 - #919191
//
// Swatch: true
//
// Styleguide Variables.Colors

//DEPRECATED, use RGBA list
$gray-000: #000;
$gray-100: #111;
$gray-170: #aaa;
$gray-200: #333;
$gray-300: #666;
$gray-400: #999;
$gray-500: #cbcbcb;
$gray-600: #ccc;
$gray-650: #c0c0c0;
$gray-700: #d8d8d8;
$gray-750: #ddd;
$gray-800: #eee;
$gray-825: #e6e6e6;
$gray-850: #f0f0f0;
$gray-885: #f5f5f5;
$gray-900: #f7f7f7;
$gray-999: #999;
$gray-1000: #919191;
$gray-1100: #e0e0e0;
$gray-80: rgb(0 0 0 / 80%); // replace $gray-200 && $gray-300
$gray-45: rgb(0 0 0 / 45%); // replace $gray-400
$gray-25: rgb(
  0 0 0 / 25%
); // replate $gray-500 && $gray-600 && $gray-700 && $gray-800

$black-01-opacity: rgb(0 0 0 / 10%);
$black-02-opacity: rgb(0 0 0 / 20%);
$black-03-opacity: rgb(0 0 0 / 30%);
$black-08-opacity: rgb(0 0 0 / 80%);
$black-004-opacity: rgb(0 0 0 / 4%);
$black-007-opacity: rgb(0 0 0 / 7%);
$black-025-opacity: rgb(0 0 0 / 25%);
$black-045-opacity: rgb(0 0 0 / 45%);
$black-055-opacity: rgb(0 0 0 / 55%);
$black-090-opacity: rgb(0 0 0 / 90%);

// Colors: Palette
//
// $blue-50 - #c2d9fd
// $blue-100 - #3483fa
// $blue-200 - #5c95ff
// $green-100 - #39b54a
// $green-200 - #64c574
// $green-300 - #ecf9f2
// $green-400 - #1bb04a
// $orange-100 - #ffa15a
// $red-100 - #ff5a5f
// $red-200 - #f04449
// $red-300 - #b00
// $yellow-100 - #ffdb15
// $yellow-200 - #ffea78
// $success-color - #39b54a
// $error-color - #ff5a5f
//
// Swatch: true
//
// Styleguide Variables.Colors

$blue-50: #c2d9fd;
$blue-100: #3483fa;
$blue-200: #5c95ff;
$blue-300: #1e6dff;
$green-100: #39b54a;
$green-200: #00a650;
$green-300: #ecf9f2;
$green-400: #1bb04a;
$green-500: #008744;
$orange-100: #f73;
$orange-200: #e6540b;
$red-100: #f23d4f;
$red-200: #f04449;
$red-300: #ff5a5f;
$red-400: #d12440;
$yellow-100: #ffdb15;
$yellow-200: #ffea78;
$platinum-bg: rgb(161 206 192 / 40%);
$gold-bg: rgb(255 230 0 / 10%);
$platinum-font: #468571;
$gold-font: #b2a100;
$success-color: $green-100;
$error-color: $red-100;

// Typography
//------------------------------
$font-size-xxxxsmall: 10px;
$font-size-xxxsmall: 11px;
$font-size-xxsmall: 12px;
$font-size-xsmall: 13px;
$font-size-s: 14px;
$font-size-m: 15px;
$font-size-base: 16px;
$font-size-l: 18px;
$font-size-xl: 20px;
$font-size-xxl: 22px;
$font-size-xxxl: 24px;
$font-size-xxxxl: 32px;
$font-weight-s: 100;
$font-weight-m: 200;
$font-weight-base: 300;
$font-weight-l: 400;
$font-weight-xl: 500;
$font-weight-xxl: 600;
$font-weight-xxxl: 700;
$font-family-primary: 'Proxima Nova', -apple-system, 'Helvetica Neue', helvetica,
  'Roboto', arial, sans-serif;
$font-family-primary-light: 'ProximaNova-light';
$font-family-primary-regular: 'ProximaNova-Regular';
$font-family-primary-semibold: 'ProximaNova-Semibold';
$font-family-primary-bold: 'ProximaNova-Bold';
$line-height-xs: 1;
$line-height-s: 1.25;
$line-height-base: 1.35;
$line-height-m: 1.45;

// Typography use cases
$font-size-title-primary: $font-size-xxl;
$font-size-title-secondary: $font-size-base;

// Layout
//------------------------------
$container-max-width: 1200px;
$gutter-xxs: 5px;
$gutter-xs: 10px;
$gutter-base: 15px;
$gutter-s: 20px;
$gutter-m: 30px;
$gutter-l: 35px;
$z-index-fullscreen: 1020; // greater than 1014 (navigation's search bar's z-index)
$z-index-xl: 10;
$z-index-l: 8;
$z-index-m: 5;
$z-index-s: 2;
$z-index-base: 1;

// Body
//------------------------------
$body-bg-color: $gray-800;
$tap-highlight-color: rgb(0 0 0 / 10%);

// Text selection color
//------------------------------
$text-selection-color: $gray-825;

// Links
//------------------------------
$link-color: $blue-100;

// Border colors
//------------------------------
$border-color--selected: $blue-100;

// Border radius
//------------------------------
$border-radius-base: 4px;
$border-radius-small: 2px;

// Blur
//------------------------------
$blur-base: 5px;

// Buttons
//------------------------------
$button-primary-text-color: $white;
$button-secondary-text-color: $blue-100;
$button-tertiary-text-color: $blue-100;
$button-primary-bg-color: $blue-100;
$button-primary-focus-bg-color: rgba($blue-100, 0.8);
$button-primary-hover-bg-color: rgba($blue-100, 0.8);
$button-secondary-focus-bg-color: rgba($blue-100, 0.65);
$button-secondary-hover-bg-color: rgba($blue-100, 0.65);
$button-tertiary-focus-bg-color: rgba($blue-100, 0.65);
$button-tertiary-hover-bg-color: rgba($blue-100, 0.65);
$button-padding: 13px 32px 12px;
$button-small-padding: 9px 24px 10px;
$button-secondary-padding: 12px 31px 11px;
$button-secondary-small-padding: 8px 23px 9px;

// Inputs
//------------------------------
$input-text-color--normal: $gray-200;
$input-text-color--focus: $gray-200;
$input-text-color--placeholder: $gray-600;
$input-line-height: $line-height-base;
$input-line-animation-height: 2px;
$input-font-size: $font-size-l;
$input-row-padding: 21px 0 0;
$input-row-margin: $gutter-xs 0;
$input-row-label-padding: 7px 11px 0 0;
$input-height: 30px;

// List
//------------------------------
$list-bg-color: $white;
$list-subtitle-font-size: $font-size-m;

// Tabs
//------------------------------
$tabs-bg-color: $yellow-100;
$tabs-link-color: #b39f4e;
$tabs-link-font-size: 17px;

// Modal
//------------------------------
$modal-bg-color: $yellow-100;
$modal-close-size: 16px;
$modal-z-index: $z-index-fullscreen;
$modal-content-z-index: $modal-z-index + 1;

// Menu actions
//------------------------------
$menu-icon-size: 17px;
$menu-box-shadow: rgb(0 0 0 / 40%);

// Badge
//------------------------------
$badge-border-radius: 50%;
$badge-xsmall: 34px;
$badge-small: 48px;
$badge-medium: 80px;
$badge-large: 90px;
$badge-icon-small: 24px;
$badge-icon-medium: 46px;
$badge-icon-large: $badge-icon-medium;

// Breadcrumb
//------------------------------
$breadcrumb-icon-size: 11px;
$breadcrumb-icon-stroke: 5px;

// Icons
//------------------------------
$icon-size: 13px !default;

// Chevron
$icon-default-chevron-stroke: $gray-500;
$icon-default-chevron-stroke-width: 3;

// Info
$icon-default-info-color: $blue-100;

// Exclamation
$icon-exclamation-size: 16px;

// Loading
$loading-size-small: 32px;
$loading-size-large: $loading-size-small * 2;
$loading-size-default: $loading-size-small;
$loading-fullscreen-mask-z-index: $modal-content-z-index + 1; // highest z-index to overlap all elements
$loading-fullscreen-spinner-z-index: $loading-fullscreen-mask-z-index + 1;
$loading-block-mask-z-index: $z-index-l - 1;
$loading-block-spinner-z-index: $loading-block-mask-z-index + 1;

// Snackbar
//------------------------------
$snackbar-z-index: $modal-content-z-index + 1;
$snackbar-color: $white;
$snackbar-close-size: 15px;

// Checkbox
//------------------------------
$checkbox-default-size: 16px;
$checkbox-embedded-size: 14px;
$checkbox-default-size-no-unit: $checkbox-default-size 1px;
$checkbox-embedded-size-no-unit: $checkbox-embedded-size 1px;
$checkbox-white: rgba($white, 0.999999);
$checkbox-gray: rgba($gray-600, 0.999999);
$checkbox-blue: rgba($blue-100, 0.999999);
$checkbox-disabled-white: rgba($white, 0.4);
$checkbox-disabled-gray: rgba($gray-300, 0.4);
$checkbox-disabled-blue: rgba($blue-100, 0.4);
$checkbox-default-label-gutter: 25px;
$checkbox-embedded-label-gutter: 20px;
$checkbox-default-uncheck-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Crect width='15' height='15' x='.5' y='.5' fill='none' fill-rule='evenodd' stroke='#{$checkbox-gray}' rx='2'/%3E%3C/svg%3E%0A");
$checkbox-default-check-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Crect id='a' width='16' height='16' rx='2'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cuse fill='#{$checkbox-blue}' xlink:href='%23a'/%3E%3Crect width='15' height='15' x='.5' y='.5' stroke='#{$checkbox-blue}' rx='2'/%3E%3C/g%3E%3Cpath stroke='#{$checkbox-white}' stroke-linecap='square' stroke-width='1.5' d='M5 8.5l1.414 1.414M7 10.5l4.243-4.243'/%3E%3C/g%3E%3C/svg%3E%0A");
$checkbox-default-group-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='#{$checkbox-blue}'%3E%3Crect width='15' height='15' x='.5' y='.5' rx='2'/%3E%3Cpath stroke-linecap='square' stroke-width='1.5' d='M5 8.5l1.414 1.414M7 10.5l4.243-4.243'/%3E%3C/g%3E%3C/svg%3E%0A");
$checkbox-embedded-uncheck-svg: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg width='#{$checkbox-embedded-size}' height='#{$checkbox-embedded-size}' viewBox='0 0 #{$checkbox-embedded-size-no-unit} #{$checkbox-embedded-size-no-unit}' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg fill='#{$checkbox-blue}'%3E%3Cpath d='M1,2.00585866 L1,11.9941413 C1,12.5502354 1.44991848,13 2.00585866,13 L11.9941413,13 C12.5502354,13 13,12.5500815 13,11.9941413 L13,2.00585866 C13,1.44976457 12.5500815,1 11.9941413,1 L2.00585866,1 C1.44976457,1 1,1.44991848 1,2.00585866 Z M0,2.00585866 C0,0.898053512 0.897060126,0 2.00585866,0 L11.9941413,0 C13.1019465,0 14,0.897060126 14,2.00585866 L14,11.9941413 C14,13.1019465 13.1029399,14 11.9941413,14 L2.00585866,14 C0.898053512,14 0,13.1029399 0,11.9941413 L0,2.00585866 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
$checkbox-embedded-check-svg: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg width='#{$checkbox-embedded-size}' height='#{$checkbox-embedded-size}' viewBox='0 0 #{$checkbox-embedded-size-no-unit} #{$checkbox-embedded-size-no-unit}' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M0,2.50291944 C0,1.1205952 1.12201312,0 2.50291944,0 L11.4970806,0 C12.8794048,0 14,1.12201312 14,2.50291944 L14,11.4970806 C14,12.8794048 12.8779869,14 11.4970806,14 L2.50291944,14 C1.1205952,14 0,12.8779869 0,11.4970806 L0,2.50291944 Z M5.44444444,11.2777778 L1.55555556,7.35149573 L2.64444444,6.25213675 L5.44444444,9.07905983 L11.3555556,3.11111111 L12.4444444,4.21047009 L5.44444444,11.2777778 Z' fill='#{$checkbox-blue}'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");

//Disabled state checkbox
$checkbox-disabled-default-uncheck-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Crect width='15' height='15' x='.5' y='.5' fill='none' fill-rule='evenodd' stroke='#{$checkbox-disabled-gray}' rx='2'/%3E%3C/svg%3E%0A");
$checkbox-disabled-default-check-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Crect id='a' width='16' height='16' rx='2'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cuse fill='#{$checkbox-disabled-blue}' xlink:href='%23a'/%3E%3Crect width='15' height='15' x='.5' y='.5' stroke='#{$checkbox-disabled-blue}' rx='2'/%3E%3C/g%3E%3Cpath stroke='#{$checkbox-disabled-white}' stroke-linecap='square' stroke-width='1.5' d='M5 8.5l1.414 1.414M7 10.5l4.243-4.243'/%3E%3C/g%3E%3C/svg%3E%0A");
$checkbox-disabled-default-group-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='#{$checkbox-disabled-blue}'%3E%3Crect width='15' height='15' x='.5' y='.5' rx='2'/%3E%3Cpath stroke-linecap='square' stroke-width='1.5' d='M5 8.5l1.414 1.414M7 10.5l4.243-4.243'/%3E%3C/g%3E%3C/svg%3E%0A");
$checkbox-disabled-embedded-uncheck-svg: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg width='#{$checkbox-embedded-size}' height='#{$checkbox-embedded-size}' viewBox='0 0 #{$checkbox-embedded-size-no-unit} #{$checkbox-embedded-size-no-unit}' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg fill='#{$checkbox-disabled-blue}'%3E%3Cpath d='M1,2.00585866 L1,11.9941413 C1,12.5502354 1.44991848,13 2.00585866,13 L11.9941413,13 C12.5502354,13 13,12.5500815 13,11.9941413 L13,2.00585866 C13,1.44976457 12.5500815,1 11.9941413,1 L2.00585866,1 C1.44976457,1 1,1.44991848 1,2.00585866 Z M0,2.00585866 C0,0.898053512 0.897060126,0 2.00585866,0 L11.9941413,0 C13.1019465,0 14,0.897060126 14,2.00585866 L14,11.9941413 C14,13.1019465 13.1029399,14 11.9941413,14 L2.00585866,14 C0.898053512,14 0,13.1029399 0,11.9941413 L0,2.00585866 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
$checkbox-disabled-embedded-check-svg: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg width='#{$checkbox-embedded-size}' height='#{$checkbox-embedded-size}' viewBox='0 0 #{$checkbox-embedded-size-no-unit} #{$checkbox-embedded-size-no-unit}' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M0,2.50291944 C0,1.1205952 1.12201312,0 2.50291944,0 L11.4970806,0 C12.8794048,0 14,1.12201312 14,2.50291944 L14,11.4970806 C14,12.8794048 12.8779869,14 11.4970806,14 L2.50291944,14 C1.1205952,14 0,12.8779869 0,11.4970806 L0,2.50291944 Z M5.44444444,11.2777778 L1.55555556,7.35149573 L2.64444444,6.25213675 L5.44444444,9.07905983 L11.3555556,3.11111111 L12.4444444,4.21047009 L5.44444444,11.2777778 Z' fill='#{$checkbox-disabled-blue}'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");

// Custom
$yellow-certification: #ffe600;
$bg-blue-section: #2968c8;
$bg-blue-background: rgb(71 154 209 / 10%);
$bg-dark-gray-gradient: rgb(241 241 241 / 34%);
$bg-light-gray-gradient: #eaeaea;
$bg-list-gray: #ededed;
$orange-bg-warning: #e6540b;
$orange-btn-hover-warning: #cc3e0a;
$orange-btn-focus-warning: #a62a08;
$blue-focus-outline: rgb(30 109 255 / 30%);
$black-012-opacity: rgb(0 0 0 / 12%);
$black-08-opacity: rgb(0 0 0 / 80%);
$andes-blue-link: #3483fa;
