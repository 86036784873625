@import '../UserPhoto/userPhoto';

// sass-lint:disable no-ids

body {
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
}

.mesh-row {
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
}

.inner-content {
  display: unset;
}

.nav-footer,
.nav-header,
[role='main'] {
  display: block;
}

.nav-header {
  background-color: #fff;
  border-bottom: 1px solid #d2d2d2;
  box-shadow: none;
  display: block;

  .nav-bounds {
    position: relative;
    padding: 48px 0 0;
  }

  .nav-bounds.nav-bounds-with-cart [for='nav-header-menu-switch'] {
    right: 12px;
  }

  .nav-bounds-large .nav-logo {
    span {
      font-weight: normal;
      font-size: 18px;
      color: #333;
    }
  }

  #nav-header-menu {
    background-color: #fff;
    border-top: 1px solid #ededed;

    &::before {
      border-bottom-color: #ededed;
    }

    &::after {
      border-bottom-color: #fff;
    }
  }

  .nav-header-categories-wrapper {
    border-bottom: 1px solid #ededed;
  }

  .categories-list {
    padding: 0 30px 0 0;
    list-style: none;

    a,
    a:active {
      border-bottom: 4px solid #ddd;
    }

    a:hover,
    a:link,
    a:visited {
      text-decoration: none;
      display: block;
      color: #333;
    }
  }

  &.nav-header-sticky {
    position: initial;
  }

  .andes-dropdown--small {
    display: none;
  }
}

[for='nav-header-user-switch'] {
  font-size: 13px;

  span {
    max-width: 65px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;
    vertical-align: middle;
  }
}

#nav-header-menu a {
  color: #333;
  border-left: none;
}

.nav-search {
  width: calc(100% - 220px);
}

.nav-bounds-with-cart .nav-search {
  left: 155px;
  right: 70px;

  .nav-search-btn {
    left: initial;
    border: none;
    background-image: none;
    top: 8px;
    right: 0;
    height: 34px;
    color: #333;
  }

  input[type='text'].nav-search-input {
    padding: 6px 50px 6px 6px;
  }

  input[type='text'].nav-search-input--hasvalue {
    border: 1px solid #eee;
  }
}

.nav-search--focus {
  background-color: #d3d3d3;
}

.header-view .nav-header {
  border: none;
}

.nav-section__user-photo {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
}

.user-photo__message {
  font-size: 18px;
  font-weight: bold;
}

.mobile-only {
  &.mobile-only {
    display: flex !important;
  }
}

.desktop-only {
  &.desktop-only {
    display: none !important;
  }
}
